export default {
    common: {
        response_400: '错误请求',
        response_401: '登录状态已过期，请重新登录',
        response_403: '服务器拒绝响应，你可能没有权限',
        response_404: '请求错误，未找到该资源',
        response_405: '请求方法未允许',
        response_408: '请求超时',
        response_429: '操作过于频繁，请稍后再试',
        response_500: '服务器端出错',
        response_501: '网络未实现',
        response_502: '网络错误',
        response_503: '服务不可用',
        response_504: '网络超时',
        response_505: 'http版本不支持该请求',
        response_59574: '项目中包含未预翻译完成的文件不可下载',
        response_default: '连接错误，CODE：{code}',
        network_error: '处理失败，请检查网络',
        notice_title: '提醒',
        notice_message_1: '缓存失效，请刷新页面后重试。',
        notice_message_2: '任务已失效',
        notice_button_1: '刷新页面',
        notice_button_2: '返回任务列表',
        notice_button_3: '确定',
        exception_message_1: 'url没有传入！',
        country_code_list: [
            {name: '阿尔巴尼亚', code: '355'},
            // {name: '阿根廷', code: '54'},
            // {name: '阿拉伯联合酋长国', code: '971'},
            // {name: '阿鲁巴', code: '297'},
            // {name: '爱尔兰', code: '353'},
            {name: '爱沙尼亚', code: '372'},
            // {name: '安道尔', code: '376'},
            // {name: '安哥拉', code: '244'},
            // {name: '安圭拉', code: '1264'},
            // {name: '安提瓜和巴布达', code: '1268'},
            // {name: '奥地利', code: '43'},
            {name: '澳大利亚', code: '61'},
            // {name: '巴巴多斯', code: '1246'},
            // {name: '巴布亚新几内亚', code: '675'},
            // {name: '巴哈马', code: '1242'},
            // {name: '巴拉圭', code: '595'},
            // {name: '巴林', code: '973'},
            // {name: '巴拿马', code: '507'},
            // {name: '巴西', code: '55'},
            // {name: '百慕大群岛', code: '1441'},
            {name: '保加利亚', code: '359'},
            // {name: '比利时', code: '32'},
            // {name: '冰岛', code: '354'},
            // {name: '波多黎各', code: '1787'},
            {name: '波兰', code: '48'},
            // {name: '波斯尼亚和黑塞哥维那', code: '387'},
            // {name: '伯利兹', code: '501'},
            // {name: '博茨瓦纳', code: '267'},
            // {name: '布基纳法索', code: '226'},
            // {name: '布隆迪', code: '257'},
            // {name: '朝鲜', code: '850'},
            // {name: '赤道几内亚', code: '240'},
            {name: '丹麦', code: '45'},
            // {name: '东帝汶', code: '670'},
            // {name: '多哥', code: '228'},
            // {name: '多米尼加共和国', code: '1809'},
            // {name: '多米尼克', code: '1767'},
            // {name: '厄立特里亚', code: '291'},
            {name: '法国', code: '33'},
            // {name: '法罗群岛', code: '298'},
            // {name: '斐济', code: '679'},
            {name: '芬兰', code: '358'},
            // {name: '佛得角', code: '238'},
            // {name: '冈比亚', code: '220'},
            // {name: '刚果共和国', code: '242'},
            // {name: '刚果民主共和国（扎伊尔）', code: '243'},
            // {name: '哥伦比亚', code: '57'},
            // {name: '哥斯达黎加', code: '506'},
            // {name: '格林纳达', code: '1473'},
            // {name: '格陵兰岛', code: '299'},
            // {name: '古巴', code: '53'},
            // {name: '关岛', code: '1671'},
            // {name: '圭亚那', code: '592'},
            // {name: '哈萨克斯坦', code: '997'},
            // {name: '海地', code: '509'},
            {name: '韩国', code: '82'},
            {name: '荷兰', code: '31'},
            // {name: '黑山', code: '382'},
            // {name: '洪都拉斯', code: '504'},
            // {name: '基里巴斯', code: '686'},
            // {name: '吉布提', code: '253'},
            // {name: '几内亚', code: '224'},
            // {name: '几内亚比绍共和国', code: '245'},
            {name: '加拿大', code: '1'},
            // {name: '加蓬', code: '241'},
            {name: '捷克', code: '420'},
            // {name: '津巴布韦', code: '263'},
            // {name: '喀麦隆', code: '237'},
            // {name: '卡塔尔', code: '974'},
            // {name: '开曼群岛', code: '1345'},
            // {name: '科摩罗', code: '269'},
            // {name: '科特迪瓦', code: '225'},
            {name: '克罗地亚', code: '385'},
            // {name: '肯尼亚', code: '254'},
            // {name: '拉脱维亚', code: '371'},
            // {name: '莱索托', code: '266'},
            {name: '老挝', code: '856'},
            {name: '立陶宛', code: '370'},
            // {name: '利比里亚', code: '231'},
            // {name: '列支敦士登', code: '423'},
            // {name: '卢森堡', code: '352'},
            // {name: '卢旺达', code: '250'},
            {name: '罗马尼亚', code: '40'},
            // {name: '马达加斯加', code: '261'},
            // {name: '马尔代夫', code: '960'},
            // {name: '马耳他', code: '356'},
            {name: '马来西亚', code: '60'},
            // {name: '马里亚纳群岛', code: '1670'},
            {name: '马其顿', code: '389'},
            // {name: '马约特', code: '262'},
            // {name: '毛里求斯', code: '230'},
            {name: '美国', code: '1'},
            // {name: '美属萨摩亚', code: '1684'},
            // {name: '美属维尔京群岛', code: '1340'},
            // {name: '蒙特塞拉特岛', code: '1664'},
            // {name: '秘鲁', code: '51'},
            // {name: '密克罗尼西亚', code: '691'},
            // {name: '摩尔多瓦', code: '373'},
            // {name: '摩洛哥', code: '212'},
            // {name: '摩纳哥', code: '377'},
            // {name: '莫桑比克', code: '258'},
            // {name: '墨西哥', code: '52'},
            // {name: '纳米比亚', code: '264'},
            // {name: '南非', code: '27'},
            // {name: '南苏丹', code: '211'},
            // {name: '尼加拉瓜', code: '505'},
            // {name: '尼日尔', code: '227'},
            // {name: '挪威', code: '47'},
            // {name: '诺福克岛', code: '6723'},
            // {name: '帕劳', code: '680'},
            {name: '葡萄牙', code: '351'},
            {name: '日本', code: '81'},
            {name: '瑞典', code: '46'},
            // {name: '瑞士', code: '41'},
            // {name: '萨尔瓦多', code: '503'},
            // {name: '萨摩亚', code: '685'},
            // {name: '塞尔维亚', code: '381'},
            {name: '塞拉利昂', code: '232'},
            // {name: '塞浦路斯', code: '357'},
            // {name: '塞舌尔', code: '248'},
            // {name: '沙特阿拉伯', code: '966'},
            // {name: '圣彼埃尔和密克隆岛', code: '508'},
            // {name: '圣多美和普林西比', code: '239'},
            // {name: '圣基茨和尼维斯', code: '1869'},
            // {name: '圣卢西亚', code: '1758'},
            // {name: '圣马丁岛（荷兰部分）', code: '1721'},
            // {name: '圣马力诺', code: '378'},
            // {name: '圣文森特和格林纳丁斯', code: '1784'},
            {name: '斯洛伐克', code: '421'},
            // {name: '斯洛文尼亚', code: '386'},
            // {name: '斯威士兰', code: '268'},
            // {name: '苏里南', code: '597'},
            // {name: '所罗门群岛', code: '677'},
            {name: '泰国', code: '66'},
            // {name: '坦桑尼亚', code: '255'},
            // {name: '汤加', code: '676'},
            // {name: '特克斯和凯科斯群岛', code: '1649'},
            // {name: '特立尼达和多巴哥', code: '1868'},
            {name: '土耳其', code: '90'},
            // {name: '瓦努阿图', code: '678'},
            // {name: '危地马拉', code: '502'},
            // {name: '委内瑞拉', code: '58'},
            // {name: '文莱', code: '673'},
            // {name: '乌拉圭', code: '598'},
            {name: '西班牙', code: '34'},
            {name: '希腊', code: '30'},
            {name: '新加坡', code: '65'},
            // {name: '新喀里多尼亚', code: '687'},
            {name: '匈牙利', code: '36'},
            // {name: '牙买加', code: '1876'},
            {name: '意大利', code: '39'},
            {name: '印度', code: '91'},
            {name: '英国', code: '44'},
            // {name: '英属维尔京群岛', code: '1284'},
            {name: '越南', code: '84'},
            // {name: '赞比亚', code: '260'},
            // {name: '直布罗陀', code: '350'},
            // {name: '智利', code: '56'},
            // {name: '中非共和国', code: '236'},
            {name: '中国澳门', code: '853'},
            {name: '中国大陆', code: '86'},
            {name: '中国台湾', code: '886'},
            {name: '中国香港', code: '852'},
        ]
    },
    component: {
        LanguageSelector_autoLang_result: '检测到{name}',
        LanguageSelector_autoLang_label: '自动检测',
        LanguageSelector_button_exchang: '点击切换',
        LanguageSelector_source_language_name: '中文',
        LanguageSelector_source_language_short_name: '中',
        LanguageSelector_target_language_name: '英语',
        LanguageSelector_target_language_short_name: '英',
        LanguageSelector_search_input_placeholder: '查找语言',

        LanguageSelect_button_exchang: '点击切换',

        PageHeader_vip_name: '套餐',
        PageHeader_help_item_1: '帮助文档',
        PageHeader_help_item_2: '使用反馈',
        PageHeader_help_item_3: '用户社区',
        PageHeader_help_item_4: '申请开票',
        PageHeader_version_title: '版本升级',
        PageHeader_version_label_new: '新',

        PageHeaderTabs_tab_memory: '记忆库',
        PageHeaderTabs_tab_term: '术语库',
        PageHeaderTabs_tab_polish: 'AI润色',
        PageHeaderTabs_tab_translationScore: 'AI翻译评分',
        PageHeaderTabs_tab_align: '语料对齐',
        PageHeaderTabs_tab_termExtraction: '术语提取',
        PageHeaderTabs_tab_qa: 'QA检查',
        PageHeaderTabs_tab_detector: '机翻检测',
        PageHeaderTabs_tab_videoTranslate: 'AI译配',
        PageHeaderTabs_tab_gptTranslate: '文档智能体',
        PageHeaderTabs_tab_ragCreate: '定制RAG模型',
        PageHeaderTabs_tab_engineCreate: '深度训练',
        PageHeaderTabs_tab_myTasks: '我的任务',
        PageHeaderTabs_tab_taskStatistics: '任务统计',
        PageHeaderTabs_tab_projectIndex: '我的项目',
        PageHeaderTabs_tab_projectTemplate: '项目模板',

        DownloadNotices_drag_tips: '按下鼠标移动位置',
        DownloadNotices_status_name_1: '导出中，请耐心等待',
        DownloadNotices_status_name_2: '导出成功',
        DownloadNotices_status_name_3: '导出失败',
        DownloadNotices_button_download: '点击下载',
        DownloadNotices_download_link_fail_message: '获取下载地址失败，请重试',
        DownloadNotices_download_delete_remote_file_success: '删除远程下载资源成功',

        DragDialog_title: '提示',

        MessageCenter_title: '消息中心',
        MessageCenter_button_read_all: '全部已读',
        MessageCenter_tab_label_all: '全部',
        MessageCenter_tab_label_unread: '未读',
        MessageCenter_tab_label_read: '已读',
        MessageCenter_message_createTime_format: 'MM月DD日 HH:mm',
        MessageCenter_button_join: '加入',
        MessageCenter_button_go_review: '去审核',
        MessageCenter_no_message_tips: '暂无消息',

        UserAvatarMenu_expireTime_format: 'YYYY-MM-DD',
        UserAvatarMenu_expireTime_label: '到期',
        UserAvatarMenu_expireTime_label_expired: '已过期',
        UserAvatarMenu_upgrade_to_pro: '升级PRO版',
        UserAvatarMenu_info_label_1: '成员数：',
        UserAvatarMenu_info_label_2: '总字符：',
        UserAvatarMenu_info_label_3: 'Token数：',
        UserAvatarMenu_button_switch_org: '切换组织',
        UserAvatarMenu_button_user_center: '个人中心',
        UserAvatarMenu_button_org_center: '企业中心',
        UserAvatarMenu_button_logout: '退出登录',
        UserAvatarMenu_message_switching_org: '正在切换组织...',

        LeftAside_slogan: '新一代AI翻译平台',
        LeftAside_menu_item_help: '帮助',
        LeftAside_menu_item_help_child_1: '帮助文档',
        LeftAside_menu_item_help_child_2: '使用反馈',
        LeftAside_menu_item_help_child_3: '用户社区',
        LeftAside_menu_item_help_child_4: '申请开票',
        LeftAside_menu_item_engine: '引擎',
        LeftAside_menu_item_project: '项目',
        LeftAside_menu_item_task: '任务',
        LeftAside_menu_item_asset: '资产',
        LeftAside_menu_item_tools: '工具',
        LeftAside_menu_item_agent: '智能体',
        LeftAside_menu_item_team: '团队',

        AlertDialog_title: '提示',
        AlertDialog_button_confirm: '确定',
        AlertDialog_button_cancel: '取消',

        BindWechat_title: '绑定微信',
        BindWechat_scan_qrcode_title: '微信扫码',
        BindWechat_button_refresh: '点击刷新',
        BindWechat_qrcode_overdue_tips: '二维码已过期',
        BindWechat_tips_1: '扫码并关注【LanguageX】公众号，',
        BindWechat_tips_2: '我们的信息不会太多，但对你很重要~',
        BindWechat_bind_success_message: '绑定成功', 

        FullLoading_title: '查询中...',

        JargonDialog_title: '术语定制（项目术语库）',
        JargonDialog_tips: '此术语库为项目自动创建，你新建项目上传或挂载的术语已生效，并保存在翻译记忆下的术语库中。',
        JargonDialog_tooltip_content: '支持xlsx/xls/tbx格式',
        JargonDialog_button_upload_title: '上传术语库',
        JargonDialog_button_download_template: '下载模板',
        JargonDialog_input_search_placeholder: '搜索术语',
        JargonDialog_table_column_index_label: '序号',
        JargonDialog_table_column_source_label: '原文',
        JargonDialog_table_column_target_label: '译文',
        JargonDialog_table_column_remark_label: '备注',
        JargonDialog_table_column_action_label: '操作',
        JargonDialog_input_source_placeholder: '输入术语原文',
        JargonDialog_input_target_placeholder: '输入术语译文',
        JargonDialog_input_remark_placeholder: '输入备注',
        JargonDialog_button_save: '保存',
        JargonDialog_button_cancel: '取消',
        JargonDialog_button_edit: '编辑',
        JargonDialog_button_delete: '删除',
        JargonDialog_button_reTranslate: '重译',
        JargonDialog_button_delete_confirm_title: '确定删除吗？',
        JargonDialog_downloading_template_message: '下载中...',
        JargonDialog_download_fail_message: '获取下载地址失败',
        JargonDialog_term_upload_verification_message: '请确认已上传文件并上传成功',
        JargonDialog_term_upload_success_message: '上传成功',
        JargonDialog_term_source_verification_message: '术语原文不能为空',
        JargonDialog_term_target_verification_message: '术语译文不能为空',
        JargonDialog_save_success_message: '保存成功',
        JargonDialog_delete_success_message: '已删除',

        NextCollapse_title: '标题',

        NextDrawer_button_close: '关闭',
        NextDrawer_button_confirm: '确认',

        PageTitle_input_title_placeholder: '输入标题，回车保存',
        PageTitle_edit_title_tooltip_content: '点击编辑，回车保存',
        PageTitle_save_title_verification_message: '名称不能为空',

        PayErpDialog_title: '购买企业版',
        PayErpDialog_success_message_1: '企业版订阅成功',
        PayErpDialog_success_message_2: '感谢您的信任，欢迎加入用户交流群~',
        PayErpDialog_success_message_3: '扫码加入官方交流群',
        PayErpDialog_button_apply_invoice: '申请开票',
        PayErpDialog_form_startTime_label: '服务开始时间',
        PayErpDialog_form_startTime_placeholder: '选择服务开始时间',
        PayErpDialog_form_endTime_label: '服务结束时间',
        PayErpDialog_form_endTime_placeholder: '选择服务结束时间',
        PayErpDialog_form_orgName_label: '企业全称',
        PayErpDialog_form_orgName_placeholder: '企业全称，必填',
        PayErpDialog_form_button_cofirm: '确认并生成协议',
        PayErpDialog_form_orgName_tips_1: '如果企业全称显示为LXO开头的内容，',
        PayErpDialog_form_orgName_tips_2: '请修改',
        PayErpDialog_form_orderNo_label: '订单编号',
        PayErpDialog_form_amount_label: '付款金额',
        PayErpDialog_form_scan_label: '扫码支付',
        PayErpDialog_form_scan_platform_wechat: '微信',
        PayErpDialog_currency_symbol: '￥',
        PayErpDialog_form_org_benefits_tips: '了解企业版本更多权益',
        PayErpDialog_form_cofirm_tips_1: '点击“确认并生成协议”按钮',
        PayErpDialog_form_cofirm_tips_2: '在新页面确认合同后返回此页面付款',
        PayErpDialog_bottom_tips_1: '订阅LanguageX服务，即表示同意我们的',
        PayErpDialog_bottom_tips_2: '《服务协议》',
        PayErpDialog_bottom_tips_3: '和',
        PayErpDialog_bottom_tips_4: '《隐私政策》',
        PayErpDialog_pay_fail_message: '支付失败!',
        PayErpDialog_orgName_verification_message: '请填写企业全称!',
        PayErpDialog_orgName_LOX_verification_message: '请联系当前企业管理员修改企业名称后再试',

        PayProDialog_title: '购买PRO版',
        PayProDialog_success_message_1: '会员订阅成功',
        PayProDialog_success_message_2: '感谢您的信任，欢迎加入用户交流群~',
        PayProDialog_success_message_3: '扫码加入官方交流群',
        PayProDialog_button_apply_invoice: '申请开票',
        PayProDialog_currency_symbol: '￥',
        PayProDialog_label_time_limit: '限时',
        PayProDialog_form_orderNo_label: '订单编号',
        PayProDialog_form_amount_label: '付款金额',
        PayProDialog_form_scan_label: '扫码支付',
        PayProDialog_form_scan_platform_wechat: '微信',
        PayProDialog_form_org_benefits_tips: '了解PRO版本更多权益',
        PayProDialog_bottom_tips_1: '订阅LanguageX服务，即表示同意我们的',
        PayProDialog_bottom_tips_2: '《服务协议》',
        PayProDialog_bottom_tips_3: '和',
        PayProDialog_bottom_tips_4: '《隐私政策》',
        PayProDialog_pay_fail_message: '支付失败!',

        QaSetting_title: 'QA设置',
        QaSetting_type_title_1: '规则QA设置',
        QaSetting_type_title_2: '智能QA设置',
        QaSetting_support_tooltip_content: '智能QA只对Pro版开放，Free用户QA只提供规则QA',
        QaSetting_fluency_tips: '英文流利度（包括用词搭配、时态、单复数、句首大小、代词/冠词、英文流利度检查）',
        QaSetting_button_cancel: '取消',
        QaSetting_button_submit: '确定',
        QaSetting_success_message: 'QA配置已更新',

        UpgradePro_message_1: '请先完成账户升级',
        UpgradePro_pro_limited_label: '已到期',
        UpgradePro_message_2: '当前正在使用的版本，暂无法使用该功能。升级账户解锁更多高级功能',
        UpgradePro_button_upgrade: '升级到PRO',

        UploadFiles_file_upload_status_fail: '失败',
        UploadFiles_file_upload_status_success: '成功',
        UploadFiles_file_accept_message: '支持{accept}格式的文件',
        UploadFiles_file_limit_message: '最多只能上传{limit}个文件',
        UploadFiles_file_size_message: '{name}大于{size}MB',
        UploadFiles_file_exist_message: '{name}已存在',
        UploadFiles_login_expired_message: '登录状态已过期，请重新登录',
        UploadFiles_file_upload_fail_message: '{name}上传失败，请重试',

        UploadOss_title: '上传文件',
        UploadOss_file_accept_message: '支持{accept}格式的文件',
        UploadOss_file_limit_message: '最多只能上传{limit}个文件',
        UploadOss_file_empty_message: '不支持上传空文件',
        UploadOss_file_size_message: '{name}大于{size}MB',
        UploadOss_file_exist_message: '{name}已存在',
        UploadOss_file_upload_fail_message: '文件上传失败，请重试',

        UserSelect_select_placeholder: '请选择',

        UserSelector_search_input_placeholder: '输入关键字搜索',
        UserSelector_empty_description: '暂无内容',

        UserSelectorDialog_title: '分配译员',
        UserSelectorDialog_form_name_label: '译员名称',
        UserSelectorDialog_form_name_placeholder: '请选择',
        UserSelectorDialog_button_cancel: '取消',
        UserSelectorDialog_button_submit: '确定',













    },
    engine: {
        config_page_title: '翻译引擎',
        config_page_button_term_setting: '术语定制',
        config_page_source_input_placeholder: '粘贴一段文字，快速对比不同引擎翻译结果，每次最多2000字',
        config_page_button_change: '换一换',
        config_page_button_try: '试一试样例',
        config_page_button_upload: '上传文件',
        config_page_tooltip_engine_detail: '查看引擎详情',
        config_page_tooltip_engine_create: '新建引擎',
        config_page_message_1: '除了通用引擎，你还可以',
        config_page_message_2: '训练自己的个性引擎',
        config_page_message_3: '当前语言方向没有个性引擎，试试自己新建一个',
        config_page_message_4: '你的PRO版账号已过期，创建的个性引擎已暂停服务，续费后恢复服务，',
        config_page_message_5: '去续费',
        config_page_message_6: '翻译中，请稍等',
        config_page_message_7: '个性引擎因会员到期已下架，续订后会恢复服务~',
        config_page_message_8: 'Pro版专属引擎，升级为Pro版可用。',
        config_page_message_9: '去升级',
        config_page_message_10: '已复制到剪切板',
        config_page_message_11: '复制到剪切板失败，请重试',
        config_page_message_12: '请选择源语言',
        config_page_message_13: '术语定制已开启',
        config_page_message_14: '术语定制已关闭',
        config_page_message_15: '当前语言方向下无此术语',
        config_page_message_16: '原文：',
        config_page_message_17: '最多润色{count}次',
        config_page_message_18: 'DeepSeek 翻译速度较慢，如需快速翻译请选用其他引擎',
        
        config_page_button_copy: '复制',
        config_page_button_edit: '编辑',
        config_page_button_show_difference: '显示差异',
        config_page_button_clear_history: '清空历史',
        config_page_button_polish: '润色',
        config_page_button_polish_again: '再次润色',

        config_page_term_title: '术语定制',
        config_page_term_descoption: '添加术语试一试，每个语言方向最多20个。如需批量添加术语和翻译文件，点击上传文件去新建项目。',
        config_page_term_table_label_1: '序号',
        config_page_term_table_label_2: '原文（{name}）',
        config_page_term_table_label_3: '译文（{name}）',
        config_page_term_table_label_4: '操作',
        config_page_term_table_input_1: '输入术语原文',
        config_page_term_table_input_2: '输入术语译文',
        config_page_term_table_button_1: '保存（Enter）',
        config_page_term_table_button_2: '删除',
        config_page_term_table_button_3: '编辑',
        config_page_term_verification_message_1: '术语原文不能为空',
        config_page_term_verification_message_2: '术语译文不能为空',
        config_page_term_message_1: '保存术语成功',
        config_page_term_message_2: '术语已删除',
        
        ragCreate_page_title: '定制RAG模型',
        ragCreate_page_alert_1_part1: '{type}最多能创建{size}个个性引擎，你的账户创建数量已达到上限，如需新建个性引擎，',
        ragCreate_page_alert_1_part2: '请联系我们',
        ragCreate_page_alert_2: 'RAG（检索增强式生成）可以让大语言模型结合记忆库/术语库和特殊要求，更专业地翻译垂直领域内容',
        ragCreate_page_form_model_label: '模型名称',
        ragCreate_page_form_model_placeholder: '给自己翻译引擎起个名字，不超过10个字符',
        ragCreate_page_form_language_label: '语言方向',
        ragCreate_page_form_language_source_placeholder: '源语言',
        ragCreate_page_form_language_target_placeholder: '目标语言',
        ragCreate_page_form_term_label: '指定术语翻译',
        ragCreate_page_form_term_tips: '在未上传术语库的情况下，指定少数项目特定的核心术语（如果术语量大请上传术语库）',
        ragCreate_page_form_term_source_placeholder: '输入术语原文',
        ragCreate_page_form_term_target_placeholder: '输入术语译文',
        ragCreate_page_form_demand_label: '其他要求',
        ragCreate_page_form_demand_tooltip_content: '如:请使用简短精炼的语言描述特定翻译要求，以下是一些例子：<br>1. 百分号直接使用%，无需翻译成percent。<br>2. 不要翻译文本中出现的公司名称，保留公司名原文。<br>3. 如果文中涉及序号，将“一、”译为“I.”。',
        ragCreate_page_form_demand_placeholder: '如：请使用简短精炼的语言描述特定翻译要求，以下是一些例子：\n1. 百分号直接使用%，无需翻译成percent。\n2. 不要翻译文本中出现的公司名称，保留公司名原文。\n3. 如果文中涉及序号，将“一、”译为“I.”。',
        ragCreate_page_form_description_label: '模型简介',
        ragCreate_page_form_description_placeholder: '请输入简介，100字以内例：国际工程领域，语料主要来自工程技术文件',
        ragCreate_page_form_memoryFile_label: '上传记忆库',
        ragCreate_page_form_memoryFile_tips: '语言方向需和引擎的语言方向一致',
        ragCreate_page_form_memoryFile_message: '上传xlsx/xls/tmx格式记忆库<br>每批最多50个文件，单个最大{size}MB',
        ragCreate_page_form_termFile_label: '上传术语',
        ragCreate_page_form_termFile_tips: '语言方向需和引擎的语言方向一致',
        ragCreate_page_form_termFile_message: '上传xlsx/xls/tbx格式术语库<br>每批最多50个文件，单个最大{size}MB',
        ragCreate_page_button_submit: '生成模型',
        ragCreate_page_button_submit_message: '预计1分钟以内',
        ragCreate_page_success_dialog_title: '生成成功',
        ragCreate_page_success_dialog_content_1: '{name}模型已生成成功',
        ragCreate_page_success_dialog_content_2: '可以到【引擎】>【个性引擎】下使用',
        ragCreate_page_success_dialog_content_3: '使用前先选择正确的语言方向',
        ragCreate_page_success_dialog_button: '去使用',
        ragCreate_page_language_zh_name: '中文',
        ragCreate_page_language_zh_shortName: '中',
        ragCreate_page_language_en_name: '英语',
        ragCreate_page_language_en_shortName: '英',
        ragCreate_page_language_ko_name: '韩语',
        ragCreate_page_language_ko_shortName: '韩',
        ragCreate_page_form_verification_message_1: '请输入模型名称',
        ragCreate_page_form_verification_message_2: '请上传记忆库',
        ragCreate_page_form_verification_message_3: '最多添加20个术语',

        create_page_title: '深度训练',
    },
    project: {
        project_index_page_btn_1: '新建项目',
        project_index_page_btn_2: '刷新',
        project_index_page_btn_3: '项目任务统计',
        project_index_page_search_input_placeholder: '输入项目名称',
        project_index_page_search_select_placeholder_1: '源语言',
        project_index_page_search_select_placeholder_2: '目标语言',
        project_index_page_search_select_placeholder_3: '按团队筛选',
        project_index_page_search_select_placeholder_4: '按状态筛选',
        project_index_page_search_select_placeholder_5: '按创建人筛选',
        project_index_page_search_dropdown_item_1: '下载译文',
        project_index_page_search_dropdown_item_2: '下载双语',
        project_index_page_search_dropdown_item_3: '下载原文',
        project_index_page_search_dropdown_item_4: '下载TMX',
        project_index_page_search_tips_1: '批量删除',
        project_index_page_search_changeView_tips_1: '切换',
        project_index_page_search_changeView_tips_2: '卡片',
        project_index_page_search_changeView_tips_3: '列表',
        project_index_page_search_changeView_tips_4: '视图',
        project_index_page_empty_tips_1: '没有找到项目～',
        project_index_page_empty_tips_2: '还没有项目，去新建一个吧～',
        project_index_page_dialog_1_title: '验收项目',
        project_index_page_dialog_1_message: '是否确认项目已完成，向智云发送任务信息？',
        project_index_page_dialog_1_btn_1: '取消',
        project_index_page_dialog_1_btn_2: '确定',
        project_index_page_data_alertInfo_message: '确定要删除项目吗？',
        project_index_page_data_alertInfo_buttonName: '确认删除',
        project_index_page_data_statusOptions_1_text: '进行中',
        project_index_page_data_statusOptions_2_text: '已验收',
        project_index_page_data_orderOptions_1_text: '按项目名排序',
        project_index_page_data_orderOptions_2_text: '按创建时间排序',
        project_index_page_methods_batchDownloadBtnEvent_message_1: '请先选择要下载的项目',
        project_index_page_methods_batchDownloadBtnEvent_message_2: '项目导出{size}个',
        project_index_page_methods_batchDeleteBtnEvent_message_1: '请先选择要删除的项目',
        project_index_page_methods_deleteProject_message_1: '项目已删除',
        project_index_page_methods_retryTask_message_1: '重试成功',
        project_index_page_methods_confirmCheck_message_1: '验收成功，请前往智云核对任务信息。',
        project_index_page_methods_executeAction_message_1: '暂无权限',
        project_index_page_methods_saveSetting2Template_message_1: '保存为模版成功',

        task_page_tab_label_1: '全部',
        task_page_tab_label_2: '待接受',
        task_page_tab_label_3: '进行中',
        task_page_tab_label_4: '已提交',
        task_page_tab_label_5: '已失效',
        task_page_search_kw_placeholder: '输入任务名称',
        task_page_search_source_placeholder: '源语言',
        task_page_search_target_placeholder: '目标语言',
        task_page_search_taskType_placeholder: '任务类型',
        task_page_search_order_placeholder: '排序',
        task_page_search_button_text_1: '批量接受',
        task_page_search_button_text_2: '批量拒绝',
        task_page_search_button_text_3: '刷新',
        task_page_search_button_text_4: '批量提交',
        task_page_search_switch_button_text_1: '切换',
        task_page_search_switch_button_text_2: '列表',
        task_page_search_switch_button_text_3: '卡片',
        task_page_search_switch_button_text_4: '视图',
        task_page_card_item_label_1: '文件名：',
        task_page_card_item_label_2: '启动：',
        task_page_card_item_label_3: '字',
        task_page_card_item_label_4: '截止：',
        task_page_card_item_label_5: '拒绝',
        task_page_card_item_label_6: '接受',
        task_page_card_item_label_7: '%',
        task_page_card_item_label_8: '导出',
        task_page_card_item_label_9: '导入',
        task_page_card_item_dropdown_item_1: '导入',
        task_page_card_item_dropdown_item_2: '导出',
        task_page_card_item_dropdown_item_3: '查看报价',
        task_page_table_colum_label_1: '任务名',
        task_page_table_colum_label_2: '任务类型',
        task_page_table_colum_label_3: '句段',
        task_page_table_colum_label_4: '项目名',
        task_page_table_colum_label_5: '语言方向',
        task_page_table_colum_label_6: '计价字数',
        task_page_table_colum_label_7: '截止时间',
        task_page_table_colum_label_8: '操作',
        task_page_table_colum_label_9: '单价',
        task_page_table_colum_label_10: '总价',
        task_page_table_colum_label_11: '状态',
        task_page_table_colum_label_12: '查看报价',

        task_page_table_colum_tips_1: '查看报价',
        task_page_table_colum_tips_2: '-退回',
        task_page_table_colum_tips_3: '导入失败：',

        task_page_empty_label_1: '没有任务～',
        task_page_dialog_title_1: '接受任务',
        task_page_dialog_message_1: '确定接受以下任务吗？',
        task_page_dialog_btn_label_1: '取消',
        task_page_dialog_btn_label_2: '确定',
        task_page_dialog_title_2: '拒绝任务',
        task_page_dialog_message_2: '确定拒绝以下任务吗？',
        task_page_dialog_input_placeholder_1: '请填写拒绝理由（必填）',
        task_page_dialog_title_3: '导入译文',
        task_page_dialog_message_3: '确定导入以下任务的译文吗？',
        task_page_dialog_title_4: '任务报价',
        task_page_dialog_4_label_1: '总金额：',
        task_page_dialog_4_label_2: '总字数：',
        task_page_dialog_4_label_3: '计价字数：',
        task_page_dialog_4_label_4: '单位价格：',
        task_page_dialog_4_label_5: '额外费用：',
        task_page_dialog_4_label_6: '计费单位：',
        task_page_dialog_4_label_7: '所属项目：',
        task_page_dialog_4_label_8: '分析明细',
        task_page_dialog_4_label_9: '币种：',
        task_page_dialog_4_table_label_1: '非译元素',
        task_page_dialog_4_table_label_2: '跨文件重复',
        task_page_dialog_4_table_label_3: '内部重复',
        task_page_dialog_4_table_label_4: '102%',
        task_page_dialog_4_table_label_5: '101%',
        task_page_dialog_4_table_label_6: '100%',
        task_page_dialog_4_table_label_7: '95%-99%',
        task_page_dialog_4_table_label_8: '85%-94%',
        task_page_dialog_4_table_label_9: '75%-84%',
        task_page_dialog_4_table_label_10: '50%-74%',
        task_page_dialog_4_table_label_11: '新字',
        task_page_dialog_4_table_label_12: '计价字数',
        task_page_dialog_4_table_label_13: '总价',
        task_page_dialog_4_table_btn_1: '关闭',
        task_page_dialog_4_table_btn_2: '下载报价',
        
        task_page_orderOptions_item_1: '按文件名正序',
        task_page_orderOptions_item_2: '按文件名倒序',
        task_page_orderOptions_item_3: '按启动时间正序',
        task_page_orderOptions_item_4: '按启动时间倒序',
        task_page_orderOptions_item_5: '按截止时间正序',
        task_page_orderOptions_item_6: '按截止时间倒序',

        task_page_validation_message_1: '请先选择任务',
        task_page_validation_message_2: '已拒绝任务',
        task_page_validation_message_3: '请先选择任务',
        task_page_validation_message_4: '已接受任务',
        task_page_validation_message_5: '请确认已上传文件并上传成功',
        task_page_validation_message_6: '导入成功，后台处理中，请2-5分钟后刷新查看',
        task_page_validation_message_7: '任务提交成功',
        task_page_downloadOfferEvent_title: '{name}-报价单',

        task_statistics_page_tab_label_1: '全部',
        task_statistics_page_tab_label_2: '待接受',
        task_statistics_page_tab_label_3: '进行中',
        task_statistics_page_tab_label_4: '已提交',
        task_statistics_page_tab_label_5: '已失效',
        task_statistics_page_search_input_placeholder: '输入任务名称',
        task_statistics_page_search_select_placeholder_1: '源语言',
        task_statistics_page_search_select_placeholder_2: '目标语言',
        task_statistics_page_search_select_placeholder_3: '任务类型',
        task_statistics_page_search_select_placeholder_4: '任务状态',
        task_statistics_page_search_select_placeholder_5: '排序',
        task_statistics_page_search_select_placeholder_6: '开始时间',
        task_statistics_page_search_select_placeholder_7: '结束时间',

        task_statistics_page_search_button_text_1: '刷新',
        task_statistics_page_search_button_text_2: '下载',
        task_statistics_page_orderOptions_item_1: '按文件名正序',
        task_statistics_page_orderOptions_item_2: '按文件名倒序',
        task_statistics_page_orderOptions_item_3: '按启动时间正序',
        task_statistics_page_orderOptions_item_4: '按启动时间倒序',
        task_statistics_page_orderOptions_item_5: '按截止时间正序',
        task_statistics_page_orderOptions_item_6: '按截止时间倒序',
        task_statistics_page_statusOptions_item_1: '待接受',
        task_statistics_page_statusOptions_item_2: '已拒绝',
        task_statistics_page_statusOptions_item_3: '进行中',
        task_statistics_page_statusOptions_item_4: '待提交',
        task_statistics_page_statusOptions_item_5: '已提交',
        task_statistics_page_table_column_lable_1: '序号',
        task_statistics_page_table_column_lable_2: '任务名',
        task_statistics_page_table_column_lable_3: '任务类型',
        task_statistics_page_table_column_lable_4: '项目名',
        task_statistics_page_table_column_lable_5: '语言方向',
        task_statistics_page_table_column_lable_6: '任务时段',
        task_statistics_page_table_column_lable_7: '任务状态',
        task_statistics_page_table_column_lable_8: '任务进度',
        task_statistics_page_table_column_lable_9: '原文字数',
        task_statistics_page_table_column_lable_10: '计价字数',
        task_statistics_page_table_column_lable_11: '单价',
        task_statistics_page_table_column_lable_12: '总价',
        task_statistics_page_table_column_lable_13: '币种',

        task_statistics_page_table_column_tips_label_1: '文件名：',
        task_statistics_page_table_column_tips_label_2: '启动：',
        task_statistics_page_table_column_tips_label_3: '暂无任务开始时间',
        task_statistics_page_table_column_tips_label_4: '暂无任务结束时间',
        task_statistics_page_table_column_tips_label_5: '%',

        task_statistics_methods_downloadBtnEvent_message_1: '当前列表没有可下载任务',

        component_ProjectCardList_popover_label_1: '项目名称：',
        component_ProjectCardList_popover_label_2: '所属团队：',
        component_ProjectCardList_popover_label_3: '创建人：',
        component_ProjectCardList_card_label_1: '文件',
        component_ProjectCardList_card_label_2: '字',
        component_ProjectCardList_card_label_3: '已验收',
        component_ProjectCardList_card_label_4: '查看',
        component_ProjectCardList_card_label_5: '%',
        component_ProjectCardList_card_label_6: '项目进度',
        component_ProjectCardList_card_dropdown_item_1: '查看',
        component_ProjectCardList_card_dropdown_item_2: '验收项目',
        component_ProjectCardList_card_dropdown_item_3: '下载译文',
        component_ProjectCardList_card_dropdown_item_4: '下载双语',
        component_ProjectCardList_card_dropdown_item_5: '下载原文',
        component_ProjectCardList_card_dropdown_item_6: '下载TMX',
        component_ProjectCardList_card_dropdown_item_7: '删除',
        component_ProjectCardList_card_dropdown_item_8: '保存为模版',
        component_ProjectCardList_card_cover_message_1: '任务失败：检测到流量不足',
        component_ProjectCardList_card_cover_message_2: '购买成功后，回到此页面点击重试',
        component_ProjectCardList_card_cover_btn_1: '购买字符包',
        component_ProjectCardList_card_cover_btn_2: '重试',
        component_ProjectCardList_card_cover_btn_3: '删除',

        component_ProjectTableList_table_column_lable_1: '项目名',
        component_ProjectTableList_table_column_lable_2: '语言方向',
        component_ProjectTableList_table_column_lable_3: '文件',
        component_ProjectTableList_table_column_lable_4: '字数',
        component_ProjectTableList_table_column_lable_5: '字符数',
        component_ProjectTableList_table_column_lable_6: '进度',
        component_ProjectTableList_table_column_lable_7: '引擎',
        component_ProjectTableList_table_column_lable_8: '创建时间',
        component_ProjectTableList_table_column_lable_9: '操作',

        component_ProjectTableList_table_column_dropdown_item_1: '查看',
        component_ProjectTableList_table_column_dropdown_item_2: '验收项目',
        component_ProjectTableList_table_column_dropdown_item_3: '下载译文',
        component_ProjectTableList_table_column_dropdown_item_4: '下载双语',
        component_ProjectTableList_table_column_dropdown_item_5: '下载原文',
        component_ProjectTableList_table_column_dropdown_item_6: '下载TMX',
        component_ProjectTableList_table_column_dropdown_item_7: '删除',
        component_ProjectTableList_table_column_dropdown_item_8: '保存为模版',

        
        component_ProjectTableList_table_column_tooltip_label_1: '项目名称：',
        component_ProjectTableList_table_column_tooltip_label_2: '所属团队：',
        component_ProjectTableList_table_column_tooltip_label_3: '创建人：',
        component_ProjectTableList_table_column_label_1: '字符流量不足',
        component_ProjectTableList_table_column_tooltip_1: '购买成功后，回到此页面点击重译',

        component_ProjectTableList_table_column_btn_1: '购买字符包',
        component_ProjectTableList_table_column_btn_2: '重试',

        project_create_page_btn_text_1: '下一步',
        project_create_page_btn_text_2: '新建',
        project_create_page_btn_text_3: '刷新',
        project_create_page_btn_text_4: '新建',
        project_create_page_btn_text_5: '刷新',
        project_create_page_btn_text_6: '添加文件',
        project_create_page_btn_text_7: '上一步',
        project_create_page_btn_text_8: '开始翻译',
        project_create_page_form_label_1: '项目名称',
        project_create_page_form_placeholder_1: '输入项目名称',
        project_create_page_form_label_2: '项目流程',
        project_create_page_form_placeholder_2: '选择项目流程',
        project_create_page_form_label_3: '所属团队',
        project_create_page_form_placeholder_3: '选择团队',
        project_create_page_form_label_4: '截止日期',
        project_create_page_form_placeholder_4: '选择日期',
        project_create_page_form_label_5: '源语言',
        project_create_page_form_placeholder_5: '选择源语言',
        project_create_page_form_label_6: '目标语言',
        project_create_page_form_placeholder_6: '选择目标语言',
        project_create_page_form_placeholder_7: '选择预翻译引擎',
        project_create_page_form_label_8: '匹配率',
        project_create_page_form_placeholder_8: '匹配率',
        project_create_page_form_label_9: '全选',
        project_create_page_form_label_10: '规则QA设置',
        project_create_page_form_label_11: '智能QA设置',
        project_create_page_form_placeholder_12: '选择参考引擎',
        project_create_page_form_placeholder_13: '输入项目备注，500字以内',
        project_create_page_form_label_14: '项目模板',
        project_create_page_form_placeholder_14: '选择项目模板',


        project_create_page_select_option_label_1: '人工翻译',

        project_create_page_tips_1: '对比引擎',
        project_create_page_tips_2: '系统会自动创建该项目默认记忆库，并将项目新产生的翻译记忆写入此库。',
        project_create_page_tips_3: '项目术语会在编辑器提示，但当前版本不支持术语干预，',
        project_create_page_tips_4: '升级到PRO解锁本功能',
        project_create_page_tips_5: '已支持术语干预',
        project_create_page_tips_6: '系统会自动创建该项目默认术语库，并将项目新产生的术语写入此库。',
        project_create_page_tips_7: '选择编辑器内参考引擎，如果不选择系统会自动推荐。',
        project_create_page_tips_8: '预览',
        project_create_page_tips_9: '项目模板列表',

        
        project_create_page_memoryTable_column_label_1: '写入',
        project_create_page_memoryTable_column_label_2: '名称',
        project_create_page_memoryTable_column_label_3: '语言方向',
        project_create_page_memoryTable_column_label_4: '条数',
        project_create_page_memoryTable_column_4_label_1: '条',
        project_create_page_memoryTable_column_label_5: '罚分',
        project_create_page_memoryTable_column_label_6: '创建时间',

        project_create_page_termsTable_column_label_1: '写入',
        project_create_page_termsTable_column_label_2: '名称',
        project_create_page_termsTable_column_label_3: '语言方向',
        project_create_page_termsTable_column_label_4: '条数',
        project_create_page_termsTable_column_4_label_1: '条',
        project_create_page_termsTable_column_label_6: '创建时间',

        project_create_page_remarkFileTable_column_label_1: '序号',
        project_create_page_remarkFileTable_column_label_2: '文件名',
        project_create_page_remarkFileTable_column_label_3: '创建时间',
        project_create_page_remarkFileTable_column_label_4: '操作',
        project_create_page_dialog_1_title: '上传风格指南文件',
        project_create_page_dialog_1_tips_1: '当前项目最多可上传',
        project_create_page_dialog_1_tips_2: '10',
        project_create_page_dialog_1_tips_3: '个文件，如有多个文件建议上传压缩包',
        project_create_page_dialog_1_btn_1: '取消',
        project_create_page_dialog_1_btn_2: '确定',

        project_create_page_data_steps_1: '上传文件',
        project_create_page_data_steps_2: '项目设置',
        project_create_page_data_tabData_tabs_1: '记忆库',
        project_create_page_data_tabData_tabs_2: '术语库',
        project_create_page_data_tabData_tabs_3: '文档设置',
        project_create_page_data_tabData_tabs_4: 'QA设置',
        project_create_page_data_tabData_tabs_5: '参考引擎',
        project_create_page_data_tabData_tabs_6: '风格指南',
        project_create_page_data_tabData_tabs_7: '高级设置',
        project_create_page_data_alertInfo_message_1: '当前上传列表中有上传中或上传失败的文件，是否继续？',
        project_create_page_data_alertInfo_submessage_1: '点击继续按钮，上传中或上传失败的文件不会带到下一步。',
        project_create_page_data_alertInfo_buttonName_1: '继续',

        project_create_page_method_nextBtnEvent_message_1: '请检查文件是否上传成功',
        project_create_page_method_nextBtnEvent_message_2: '请上传文件',
        project_create_page_method_nextBtnEvent_message_3: '请选择引擎',
        project_create_page_method_confirmNextEvent_message_1: '请检查文件是否上传成功',
        project_create_page_method_confirmNextEvent_message_2: '请上传文件',
        project_create_page_method_startTransitionBtnEvent_message_1: '请输入项目名称',
        project_create_page_method_startTransitionBtnEvent_message_2: '请选择截止日期',
        project_create_page_method_startTransitionBtnEvent_message_3: '请选择目标语言',
        project_create_page_method_startTransitionBtnEvent_message_4: '请为已选择的语言方向设置预翻译引擎',
        project_create_page_method_gotoTermPage_message_1: '请选择目标语言',
        project_create_page_method_gotoMemoryPage_message_2: '请选择目标语言',
        project_create_page_method_beforeRouteLeave_message_1: '确定要离开吗？离开会清空当前配置信息',
        project_create_page_method_beforeRouteLeave_message_2: '提示',
        project_create_page_method_beforeRouteLeave_btn_1: '取消',
        project_create_page_method_beforeRouteLeave_btn_2: '确定',


        project_new_page_btn_text_1: '下一步',
        project_new_page_btn_text_2: '上一步',
        project_new_page_btn_text_3: '新建',
        project_new_page_btn_text_4: '刷新',
        project_new_page_btn_text_5: '新建',
        project_new_page_btn_text_6: '刷新',
        project_new_page_btn_text_7: '开始翻译',
        project_new_page_form_placeholder_1: '粘贴一段文字，快速对比不同引擎翻译结果，每次最多2000字',
        project_new_page_form_label_2: '项目名称',
        project_new_page_form_placeholder_2: '输入项目名称',
        project_new_page_form_label_3: '机翻引擎',
        project_new_page_form_label_4: '语言方向',
        project_new_page_form_label_5: '匹配率',
        project_new_page_form_placeholder_5: '匹配率',
        project_new_page_form_label_6: '全选',
        project_new_page_form_label_7: '规则QA设置',
        project_new_page_form_label_8: '智能QA设置',

        project_new_page_tips_1: '查看引擎详情',
        project_new_page_tips_2: '新建引擎',
        project_new_page_tips_3: '部分大模型翻译速度较慢，如需翻译文档，建议优先LanguageX和ChatGPT',
        project_new_page_tips_4: '系统会自动创建该项目默认记忆库，并将项目新产生的翻译记忆写入此库。',
        project_new_page_tips_5: '项目术语会在编辑器提示，但当前版本不支持术语干预，',
        project_new_page_tips_6: '升级到PRO解锁本功能',
        project_new_page_tips_7: '已支持术语干预',
        project_new_page_tips_8: '系统会自动创建该项目默认术语库，并将项目新产生的术语写入此库。',
        project_new_page_tips_9: '选择编辑器内参考引擎',
        project_new_page_tips_10: '预览',
        project_new_page_tips_11: '换一换',
        project_new_page_tips_12: '试一试样例',
        project_new_page_tips_13: '锁定设置',
        project_new_page_tips_14: '计价字数',

        project_new_page_memoryTable_column_label_1: '写入',
        project_new_page_memoryTable_column_label_2: '名称',
        project_new_page_memoryTable_column_label_3: '语言方向',
        project_new_page_memoryTable_column_label_4: '条数',
        project_new_page_memoryTable_column_4_label_1: '条',
        project_new_page_memoryTable_column_label_5: '创建时间',

        project_new_page_termsTable_column_label_1: '写入',
        project_new_page_termsTable_column_label_2: '名称',
        project_new_page_termsTable_column_label_3: '语言方向',
        project_new_page_termsTable_column_label_4: '条数',
        project_new_page_termsTable_column_4_label_1: '条',
        project_new_page_termsTable_column_label_5: '创建时间',

        project_new_page_data_steps_1: '上传文件',
        project_new_page_data_steps_2: '选择机器翻译引擎',
        project_new_page_data_steps_3: '项目设置',
        project_new_page_data_tabData_tabs_1: '记忆库',
        project_new_page_data_tabData_tabs_2: '术语库',
        project_new_page_data_tabData_tabs_3: '文档设置',
        project_new_page_data_tabData_tabs_4: 'QA设置',
        project_new_page_data_tabData_tabs_5: '参考引擎',
        project_new_page_data_alertInfo_message_1: '当前上传列表中有上传中或上传失败的文件，是否继续？',
        project_new_page_data_alertInfo_submessage_1: '点击继续按钮，上传中或上传失败的文件不会带到下一步。',
        project_new_page_data_alertInfo_buttonName_1: '继续',
        project_new_page_method_nextBtnEvent_message_1: '请检查文件是否上传成功',
        project_new_page_method_nextBtnEvent_message_2: '请上传文件',
        project_new_page_method_nextBtnEvent_message_3: '请选择引擎',
        project_new_page_method_confirmNextEvent_message_1: '请检查文件是否上传成功',
        project_new_page_method_confirmNextEvent_message_2: '请上传文件',
        project_new_page_method_changeEngineEvent_message_1: 'Pro版专属引擎，升级为Pro版可用。',
        project_new_page_method_changeEngineEvent_message_2: '去升级',
        project_new_page_method_changeEngineEvent_message_3: 'DeepSeek 翻译速度较慢，如需快速翻译请选用其他引擎',
        project_new_page_method_beforeRouteLeave_message_1: '确定要离开吗？离开会清空当前配置信息',
        project_new_page_method_beforeRouteLeave_message_2: '提示',
        project_new_page_method_beforeRouteLeave_btn_1: '取消',
        project_new_page_method_beforeRouteLeave_btn_2: '确定',

        component_CreateUpload_tips_1: '最多上传{count}个文件（单个文件最大{size}MB）',
        component_CreateUpload_tips_2: '暂只支持可编辑PDF。扫描版/加密PDF请处理后再上传',
        component_CreateUpload_tips_3: '{count1}文件成功，{count2}文件失败',
        component_CreateUpload_tips_4: '继续上传文件',
        component_CreateUpload_data_uploadFileMessage: '支持{types}格式的文件',
        component_CreateUpload_method_beforeUploadEvent_message_1: '最多只能上传{count}个文件',
        component_CreateUpload_method_beforeUploadEvent_message_2: '不支持上传空文件',
        component_CreateUpload_method_beforeUploadEvent_message_3: '{fileName}超过{size}MB',
        component_CreateUpload_method_beforeUploadEvent_message_4: '{fileName}已存在',
        component_CreateUpload_method_beforeUploadEvent_message_5: '登录状态已过期，请重新登录',
        component_CreateUpload_method_beforeUploadEvent_message_6: '上传失败，请重试',
        component_CreateMemoryMultiLanguage_dialog_title: '新建记忆库',
        component_CreateMemoryMultiLanguage_dialog_form_item_label_1: '名称',
        component_CreateMemoryMultiLanguage_dialog_form_item_placeholder_1: '输入记忆库名称',
        component_CreateMemoryMultiLanguage_dialog_form_item_label_2: '所属团队',
        component_CreateMemoryMultiLanguage_dialog_form_item_placeholder_2: '选择团队',
        component_CreateMemoryMultiLanguage_dialog_form_item_label_3: '上传记忆库',
        component_CreateMemoryMultiLanguage_tips_5: '支持{types}，最大{size}M。点击下载模版：',
        component_CreateMemoryMultiLanguage_tips_6: '选择上传文件',
        component_CreateMemoryMultiLanguage_btn_1: '取消',
        component_CreateMemoryMultiLanguage_btn_2: '保存',
        component_CreateMemoryMultiLanguage_method_downloadTemplateEvent_message_1: '下载中...',
        component_CreateMemoryMultiLanguage_method_downloadTemplateEvent_message_2: '获取下载地址失败',
        component_CreateMemoryMultiLanguage_method_saveBtnEvent_message_1: '记忆库名称必须填写',
        component_CreateMemoryMultiLanguage_method_saveBtnEvent_message_2: '请至少上传1个语种对应的记忆库文件',
        component_CreateMemoryMultiLanguage_method_saveBtnEvent_message_3: '记忆库创建成功',

        component_CreateTermMultiLanguage_dialog_title: '新建术语库',
        component_CreateTermMultiLanguage_dialog_form_item_label_1: '名称',
        component_CreateTermMultiLanguage_dialog_form_item_placeholder_1: '输入术语库名称',
        component_CreateTermMultiLanguage_dialog_form_item_label_2: '所属团队',
        component_CreateTermMultiLanguage_dialog_form_item_placeholder_2: '选择团队',
        component_CreateTermMultiLanguage_dialog_form_item_label_3: '上传术语',
        component_CreateTermMultiLanguage_tips_5: '支持{types}，最大{size}M。点击下载模版：',
        component_CreateTermMultiLanguage_tips_6: '选择上传文件',
        component_CreateTermMultiLanguage_btn_1: '取消',
        component_CreateTermMultiLanguage_btn_2: '保存',
        component_CreateTermMultiLanguage_method_downloadTemplateEvent_message_1: '下载中...',
        component_CreateTermMultiLanguage_method_downloadTemplateEvent_message_2: '获取下载地址失败',
        component_CreateTermMultiLanguage_method_saveBtnEvent_message_1: '术语库名称必须填写',
        component_CreateTermMultiLanguage_method_saveBtnEvent_message_2: '请至少上传1个语种对应的术语库文件',
        component_CreateTermMultiLanguage_method_saveBtnEvent_message_3: '术语库创建成功',

        
        project_list_page_tabPane_1_label: '项目文件',
        project_list_page_tabPane_1_btn_1: '添加文件',
        project_list_page_tabPane_1_btn_2: '刷新',
        project_list_page_tabPane_1_tips_1: '总共{total}个，选中{size}个',
        project_list_page_tabPane_1_tips_2: '批量删除',
        project_list_page_tabPane_1_input_placeholder_1: '输入文件名称',
        project_list_page_tabPane_1_dropdownItem_1: '下载译文',
        project_list_page_tabPane_1_dropdownItem_2: '下载双语',
        project_list_page_tabPane_1_dropdownItem_3: '下载原文',
        project_list_page_tabPane_1_dropdownItem_4: '下载TMX',
        project_list_page_tabPane_1_dropdownItem_5: '下载编辑痕迹',
        project_list_page_tabPane_1_dropdownItem_6: '下载合并报价单',

        component_FileListInside_table_column_1: '文件名',
        component_FileListInside_table_column_2: '语言方向',
        component_FileListInside_table_column_3: '字数 / 字符',
        component_FileListInside_table_column_4: '进度',
        component_FileListInside_table_column_5: '负责人',
        component_FileListInside_table_column_6: '时间',
        component_FileListInside_table_column_7: '操作',
        component_FileListInside_tips_1: '句段',
        component_FileListInside_tips_2: '字',
        component_FileListInside_tips_3: '拒绝原因：',
        component_FileListInside_tips_4: '退回',
        component_FileListInside_tips_5: '（退回）',
        component_FileListInside_tips_6: '变更负责人',
        component_FileListInside_tips_7: '分配负责人',
        component_FileListInside_tips_8: '等{size}人',
        component_FileListInside_tips_9: '未分配',
        component_FileListInside_tips_10: '未设置',
        component_FileListInside_tips_11: '查看联系方式',
        component_FileListInside_tips_12: '退回至此阶段',
        component_FileListInside_tips_13: '查看报价',
        component_FileListInside_tips_14: '字',
        component_FileListInside_tips_15: '字符',
        component_FileListInside_tips_16: '字符流量不足',
        component_FileListInside_tips_17: '购买成功后，回到此页面点击重译',
        component_FileListInside_tips_18: '拆分',
        component_FileListInside_tips_19: '查看',
        component_FileListInside_tips_20: '下载',
        component_FileListInside_tips_21: '修改流程',
        component_FileListInside_tips_22: '查看历史',
        component_FileListInside_tips_23: '删除文件',
        component_FileListInside_input_placeholder_1: '选择截止时间',
        component_FileListInside_btn_1: '购买字符包',
        component_FileListInside_btn_2: '重译',
        component_FileListInside_dropdownItem_1: '下载译文',
        component_FileListInside_dropdownItem_2: '下载双语',
        component_FileListInside_dropdownItem_3: '下载原文',
        component_FileListInside_dropdownItem_4: '下载TMX',
        component_FileListInside_dropdownItem_5: '下载编辑痕迹',
        component_FileListInside_dialog_1_title: '联系方式',
        component_FileListInside_dialog_2_title: '退回任务',
        component_FileListInside_dialog_2_tips_1: '任务将退回至',
        component_FileListInside_dialog_2_tips_2: '阶段',
        component_FileListInside_dialog_2_formItem_label_1: '退回原因',
        component_FileListInside_dialog_2_formItem_input_placeholder_1: '填写退回原因',
        component_FileListInside_dialog_2_btn_1: '取消',
        component_FileListInside_dialog_2_btn_2: '确定',
        component_FileListInside_dialog_3_title: '历史记录',
        component_FileListInside_dialog_4_title: '拆分',
        component_FileListInside_dialog_4_tips_1: '拆分为',
        component_FileListInside_dialog_4_tips_2: '份',
        component_FileListInside_dialog_4_btn_1: '取消',
        component_FileListInside_dialog_4_btn_2: '确定',
        component_FileListInside_dialog_5_title: '任务报价',
        component_FileListInside_dialog_5_tips_1: '总金额：',
        component_FileListInside_dialog_5_tips_2: '总字数：',
        component_FileListInside_dialog_5_tips_3: '计价字数：',
        component_FileListInside_dialog_5_tips_4: '单位价格：',
        component_FileListInside_dialog_5_tips_5: '币种：',
        component_FileListInside_dialog_5_tips_6: '额外费用：',
        component_FileListInside_dialog_5_tips_7: '计费单位：',
        component_FileListInside_dialog_5_tips_8: '所属项目：',
        component_FileListInside_dialog_5_tips_9: '分析明细',
        component_FileListInside_dialog_5_table_column_1: '非译元素',
        component_FileListInside_dialog_5_table_column_2: '跨文件重复',
        component_FileListInside_dialog_5_table_column_3: '内部重复',
        component_FileListInside_dialog_5_table_column_4: '102%',
        component_FileListInside_dialog_5_table_column_5: '101%',
        component_FileListInside_dialog_5_table_column_6: '100%',
        component_FileListInside_dialog_5_table_column_7: '95%-99%',
        component_FileListInside_dialog_5_table_column_8: '85%-94%',
        component_FileListInside_dialog_5_table_column_9: '75%-84%',
        component_FileListInside_dialog_5_table_column_10: '50%-74%',
        component_FileListInside_dialog_5_table_column_11: '新字',
        component_FileListInside_dialog_5_table_column_12: '计价字数',
        component_FileListInside_dialog_5_table_column_13: '总价',
        component_FileListInside_dialog_5_btn_1: '关闭',
        component_FileListInside_dialog_5_btn_2: '下载报价',
        component_FileListInside_method_showAssignDialog_message_1: '当前任务{status}，不能分配。',
        component_FileListInside_method_setDeadLineTime_message_1: '截止日期不能小于或等于文件上传日期',
        component_FileListInside_method_submitRejectEvent_message_1: '请填写退回原因',
        component_FileListInside_method_copyText_message_1: '已复制到剪切板',
        component_FileListInside_method_copyText_message_2: '复制到剪切板失败，请重试',
        component_FileListInside_method_submitSplitEvent_message_1: '文档拆分成功',
        component_FileListInside_method_downloadOfferEvent_message_1: '报价单',

        component_FileList_table_column_1: '文件名',
        component_FileList_table_column_2: '机翻状态',
        component_FileList_table_column_3: '译后编辑进度',
        component_FileList_table_column_4: '字数 / 字符',
        component_FileList_table_column_5: '上传时间',
        component_FileList_table_column_6: '操作',
        component_FileList_tips_1: '字符流量不足',
        component_FileList_tips_2: '购买成功后，回到此页面点击重译',
        component_FileList_tips_3: '在译后编辑页面中，对需要处理的译文进行编辑后点击右侧的【确认】键，译后编辑进度便会进行更新',
        component_FileList_tips_4: '译后编辑进度',
        component_FileList_tips_5: '字',
        component_FileList_tips_6: '字符',
        component_FileList_tips_7: '删除文件',
        component_FileList_btn_1: '购买字符包',
        component_FileList_btn_2: '重译',
        component_FileList_dropdown_item_1: '下载译文',
        component_FileList_dropdown_item_2: '下载双语',
        component_FileList_dropdown_item_3: '下载原文',
        component_FileList_dropdown_item_4: '下载TMX',

        project_list_page_tabPane_2_label: '任务规划',

        component_TaskPlanList_btn_1: '刷新',
        component_TaskPlanList_btn_2: '分配',
        component_TaskPlanList_btn_3: '定时',
        component_TaskPlanList_select_placeholder_1: '文件',
        component_TaskPlanList_select_placeholder_2: '任务类型',
        component_TaskPlanList_select_placeholder_3: '目标语言',
        component_TaskPlanList_select_placeholder_4: '负责人',
        component_TaskPlanList_select_placeholder_5: '状态',
        component_TaskPlanList_input_placeholder_1: '选择截止时间',
        component_TaskPlanList_tips_1: '任务规划页面仅展示未接受的任务，可对任务进行批量分配和定时',
        component_TaskPlanList_tips_2: '（退回）',
        component_TaskPlanList_tips_3: '句段',
        component_TaskPlanList_tips_4: '未启动',
        component_TaskPlanList_tips_5: '未设置',
        component_TaskPlanList_tips_6: '变更负责人',
        component_TaskPlanList_tips_7: '分配负责人',
        component_TaskPlanList_tips_8: '等{size}人',
        component_TaskPlanList_tips_9: '未分配',
        component_TaskPlanList_tips_10: '查看联系方式',
        component_TaskPlanList_tips_11: '等',
        component_TaskPlanList_tips_12: '个任务',
        component_TaskPlanList_table_column_1: '任务',
        component_TaskPlanList_table_column_2: '句段',
        component_TaskPlanList_table_column_3: '文件名',
        component_TaskPlanList_table_column_4: '语言方向',
        component_TaskPlanList_table_column_5: '启动时间',
        component_TaskPlanList_table_column_6: '截止时间',
        component_TaskPlanList_table_column_7: '状态',
        component_TaskPlanList_table_column_8: '负责人',
        component_TaskPlanList_table_column_9: '操作',
        component_TaskPlanList_dialog_1_title: '联系方式',
        component_TaskPlanList_dialog_2_title: '任务定时',
        component_TaskPlanList_dialog_2_input_placeholder_1: '选择截止时间',
        component_TaskPlanList_dialog_2_btn_1: '取消',
        component_TaskPlanList_dialog_2_btn_2: '确定',
        component_TaskPlanList_method_batchAssignTask_message_1: '请选择任务',
        component_TaskPlanList_method_batchSetDeadline_message_1: '请选择任务',
        component_TaskPlanList_method_submitDeadlineEvent_message_1: '请选择截止时间',
        component_TaskPlanList_method_copyText_message_1: '已复制到剪切板',
        component_TaskPlanList_method_copyText_message_2: '复制到剪切板失败，请重试',

        project_list_page_tabPane_3_label: '基础设置',
        project_list_page_tabPane_3_tips_1: '项目名称',
        project_list_page_tabPane_3_tips_2: '所属团队',
        project_list_page_tabPane_3_tips_3: '创建人',
        project_list_page_tabPane_3_tips_4: '创建时间',
        project_list_page_tabPane_3_tips_5: '项目流程',
        project_list_page_tabPane_3_tips_6: '语言方向',
        project_list_page_tabPane_3_tips_7: '预翻译引擎',
        project_list_page_tabPane_3_tips_8: '参考翻译引擎',
        project_list_page_tabPane_3_tips_9: '人工翻译',
        project_list_page_tabPane_3_tips_10: '高级设置',
        project_list_page_tabPane_3_tips_11: '1、锁定项在编辑器优先次序为：非译元素>内部重复>跨文件重复>100%匹配>101%匹配>102%匹配',
        project_list_page_tabPane_3_tips_12: '2、计价字数设置对所有文件生效；锁定设置仅对追加文件生效。',
        project_list_page_tabPane_3_tips_13: '锁定设置',
        project_list_page_tabPane_3_tips_14: '计价字数',
        project_list_page_tabPane_3_input_placeholder_1: '输入项目名称',
        project_list_page_tabPane_3_select_placeholder_1: '请选择',
        project_list_page_tabPane_3_btn_1: '确认修改',
        project_list_page_tabPane_3_btn_2: '保存',

        project_list_page_tabPane_4_label: '记忆库',
        project_list_page_tabPane_4_btn_1: '新建',
        project_list_page_tabPane_4_btn_2: '刷新',
        project_list_page_tabPane_4_tips_1: '匹配率',
        project_list_page_tabPane_4_tips_2: '条',
        project_list_page_tabPane_4_select_placeholder_1: '匹配率',
        project_list_page_tabPane_4_table_column_1: '写入',
        project_list_page_tabPane_4_table_column_2: '名称',
        project_list_page_tabPane_4_table_column_3: '语言方向',
        project_list_page_tabPane_4_table_column_4: '条数',
        project_list_page_tabPane_4_table_column_5: '罚分',
        project_list_page_tabPane_4_table_column_6: '创建时间',
        
        project_list_page_tabPane_5_label: '术语库',
        project_list_page_tabPane_5_tips_1: '项目术语会在编辑器提示，但当前版本不支持术语干预，',
        project_list_page_tabPane_5_tips_2: '升级到PRO解锁本功能',
        project_list_page_tabPane_5_tips_3: '{type}已支持术语干预',
        project_list_page_tabPane_5_tips_4: '条',
        project_list_page_tabPane_5_btn_1: '新建',
        project_list_page_tabPane_5_btn_2: '刷新',
        project_list_page_tabPane_5_table_column_1: '写入',
        project_list_page_tabPane_5_table_column_2: '名称',
        project_list_page_tabPane_5_table_column_3: '语言方向',
        project_list_page_tabPane_5_table_column_4: '条数',
        project_list_page_tabPane_5_table_column_5: '创建时间',

        project_list_page_tabPane_6_label: '文档设置',
        project_list_page_tabPane_6_checkbox_label_1: '全选',

        project_list_page_tabPane_7_label: 'QA设置',
        project_list_page_tabPane_7_tips_1: '规则QA设置',
        project_list_page_tabPane_7_tips_2: '智能QA设置',

        project_list_page_tabPane_8_label: '风格指南',
        project_list_page_tabPane_8_tips_1: '备注说明',
        project_list_page_tabPane_8_tips_2: '暂无备注说明',
        project_list_page_tabPane_8_input_placeholder_1: '输入项目备注',
        project_list_page_tabPane_8_btn_1: '添加文件',
        project_list_page_tabPane_8_table_column_1: '序号',
        project_list_page_tabPane_8_table_column_2: '文件名',
        project_list_page_tabPane_8_table_column_3: '创建时间',
        project_list_page_tabPane_8_table_column_4: '操作',
        project_list_page_tabPane_8_dialog_1_title: '上传风格指南文件',
        project_list_page_tabPane_8_dialog_1_tips_1: '当前项目最多可上传',
        project_list_page_tabPane_8_dialog_1_tips_2: '个文件，如有多个文件建议上传压缩包',
        project_list_page_tabPane_8_dialog_1_btn_1: '取消',
        project_list_page_tabPane_8_dialog_1_btn_2: '确定',

        project_list_page_tabPane_9_label: '文件分析',
        component_FileAnalysis_tips_1: '统计维度：',
        component_FileAnalysis_tips_2: '总计',
        component_FileAnalysis_tips_3: '50%以下匹配都属于新字',
        component_FileAnalysis_tips_4: '指当前段落100%匹配基础上,前一段落或后一段落匹配。',
        component_FileAnalysis_tips_5: '指的是不仅当前段落与记忆库存储的相同,而且之前和之后的段落也是相同的',
        component_FileAnalysis_tips_6: '百分比结果四舍五入保留整数，仅供参考',
        component_FileAnalysis_tips_7: '%',
        component_FileAnalysis_tips_8: '表示的是字符的个数，一个中文字算作一个字符，一个由5个英文字母组成的英文单词算作5个字符。',
        component_FileAnalysis_tips_9: '字符数',
        component_FileAnalysis_tips_10: '表示的是单词的个数，一个中文字即算一个字；英文一个单词算一个字。',
        component_FileAnalysis_tips_11: '字数',
        component_FileAnalysis_tips_12: '表示是中文字符和朝鲜语单词，一般就是纯中文字数，不包括英文单词和阿拉伯数字。',
        component_FileAnalysis_tips_13: '中朝字数',
        component_FileAnalysis_select_placeholder_1: '请选择',
        component_FileAnalysis_btn_1: '下载文件分析报告',
        component_FileAnalysis_table_column_1: '句段',
        component_FileAnalysis_table_column_2: '中朝字数',
        component_FileAnalysis_method_downloadFileAnalysis_title: '{name}文件分析报告',

        project_list_page_tabPane_10_label: '任务统计',
        component_ProjectTaskStatisticsTableList_btn_1: '下载',

        component_ProjectTaskStatisticsTableList_tips_1: '暂无任务开始时间',
        component_ProjectTaskStatisticsTableList_tips_2: '暂无任务结束时间',
        component_ProjectTaskStatisticsTableList_tips_3: '%',
        component_ProjectTaskStatisticsTableList_table_column_1: '负责人',
        component_ProjectTaskStatisticsTableList_table_column_2: '任务名',
        component_ProjectTaskStatisticsTableList_table_column_3: '任务类型',
        component_ProjectTaskStatisticsTableList_table_column_4: '语言方向',
        component_ProjectTaskStatisticsTableList_table_column_5: '任务时段',
        component_ProjectTaskStatisticsTableList_table_column_6: '任务进度',
        component_ProjectTaskStatisticsTableList_table_column_7: '任务字数',
        component_ProjectTaskStatisticsTableList_table_column_8: '计价字数',
        component_ProjectTaskStatisticsTableList_table_column_9: '单价',
        component_ProjectTaskStatisticsTableList_table_column_10: '币种',
        component_ProjectTaskStatisticsTableList_table_column_11: '总价',
        component_ProjectTaskStatisticsTableList_method_handleDownloadBtnEvent_title: '任务统计',

        project_list_page_dialog_1_title: '添加文件',
        project_list_page_dialog_1_tips_1: '当前项目最多可上传',
        project_list_page_dialog_1_tips_2: '个文件，还可上传',
        project_list_page_dialog_1_tips_3: '个文件',
        project_list_page_dialog_1_btn_1: '取消',
        project_list_page_dialog_1_btn_2: '确定',

        project_list_page_dialog_2_title: '提示',
        project_list_page_dialog_2_tips_1: '注意：项目流程修改仅支持对项目中未启动的文档进行重置，后续追加的文档会使用最新的流程。若您需要修改项目中已启动的文档，请在项目文件中对单个文档流程进行修改。',
        project_list_page_dialog_2_tips_2: '是否确认修改项目流程？',
        project_list_page_dialog_2_btn_1: '取消',
        project_list_page_dialog_2_btn_2: '确定',

        project_list_page_dialog_3_title: '提示',
        project_list_page_dialog_3_tips_1: '注意：对已启动的文件修改流程会导致所有内容（负责人、进度、编辑内容等）重置，请谨慎操作！',
        project_list_page_dialog_3_tips_2: '是否确定修改当前文件的翻译流程？',
        project_list_page_dialog_3_btn_1: '取消',
        project_list_page_dialog_3_btn_2: '确定',

        project_list_page_dialog_4_title: '修改文件流程',
        project_list_page_dialog_4_form_item_label_1: '文件流程',
        project_list_page_dialog_4_btn_1: '取消',
        project_list_page_dialog_4_btn_2: '确定',
        project_list_page_data_upload_supportFiles: '支持{type}格式的文件',
        project_list_page_data_alertInfo_message: '确定要删除文件吗？',
        project_list_page_data_alertInfo_buttonName: '确认删除',
        project_list_page_method_deleteDocumentEvent_message_1: '已删除',
        project_list_page_method_batchDeleteBtnEvent_message_1: '请选择要删除的文档',
        project_list_page_method_handleBatchDownloadCommand_message_1: '请选择要下载的文档',
        project_list_page_method_handleBatchDownloadCommand_message_2: '项目文件导出{size}个',
        project_list_page_method_submitAppendFile_message_1: '当前新上传的文件中有 {size} 个同名文件，请修改文件名后上传',
        project_list_page_method_saveProjectName_message_1: '项目名称修改成功',
        project_list_page_method_saveProjectRemark_message_1: '项目备注修改成功',
        project_list_page_method_saveProjectWorkflowEdit_message_1: '项目流程修改成功',
        project_list_page_method_saveFlieWorkflowEdit_message_1: '文件流程修改成功',
        project_list_page_method_setProjectsSetting_message_1: '保存中...',
        project_list_page_method_handleMemoryPenaltyPointsChange_message_1: '保存中...',
        project_list_page_method_qaSettingItemChange_message_1: '保存中...',
        project_list_page_method_updateRemarkFile_message_1: '保存中...',
        project_list_page_method_downloadBatchOfferEvent_title: '{name}-报价单',
        project_list_page_method_submitFileAnalysisSettingDialog_message_1: '保存中...',

        component_TaskAssignDialog_dialog_title: '任务分配',
        component_TaskAssignDialog_dialog_tips_1: '{size}句',
        component_TaskAssignDialog_dialog_tips_2: '等',
        component_TaskAssignDialog_dialog_tips_3: '个任务',
        component_TaskAssignDialog_dialog_tips_4: '{size}字',
        component_TaskAssignDialog_dialog_tips_5: '负责人：',
        component_TaskAssignDialog_dialog_tips_6: '等{size}人',
        component_TaskAssignDialog_dialog_tips_7: 'Preferred Vendor List，指资源中心提供的客户精选资源清单',
        component_TaskAssignDialog_dialog_tips_8: '包含',
        component_TaskAssignDialog_dialog_tips_9: '介于',
        component_TaskAssignDialog_dialog_tips_10: '额外费用',
        component_TaskAssignDialog_dialog_tips_11: '总价=任务单价*计价字数+额外费用',
        component_TaskAssignDialog_dialog_tips_12: '句段',
        component_TaskAssignDialog_dialog_tips_13: '如选中多人，则相当于发起认领模式，先接受任务者为任务负责人',

        component_TaskAssignDialog_dialog_btn_1: '收起',
        component_TaskAssignDialog_dialog_btn_2: '查询',
        component_TaskAssignDialog_dialog_btn_3: '清空',
        component_TaskAssignDialog_dialog_btn_4: '取消',
        component_TaskAssignDialog_dialog_btn_5: '确定',

        component_TaskAssignDialog_dialog_input_placeholder_1: '输入姓名',
        component_TaskAssignDialog_dialog_input_placeholder_2: '/手机号/邮箱',
        component_TaskAssignDialog_dialog_input_placeholder_3: '/手机尾号',
        component_TaskAssignDialog_dialog_input_placeholder_4: '搜索',
        component_TaskAssignDialog_dialog_input_placeholder_5: '最低价格',
        component_TaskAssignDialog_dialog_input_placeholder_6: '最高价格',
        component_TaskAssignDialog_dialog_checkbox_label_1: '只在PVL表中查找',

        component_TaskAssignDialog_dialog_form_item_1_label: '业务线',
        component_TaskAssignDialog_dialog_form_item_2_label: '客户',
        component_TaskAssignDialog_dialog_form_item_3_label: '任务类型',
        component_TaskAssignDialog_dialog_form_item_4_label: '统计单位',
        component_TaskAssignDialog_dialog_form_item_5_label: '擅长领域',
        component_TaskAssignDialog_dialog_form_item_6_label: '币种',
        component_TaskAssignDialog_dialog_form_item_7_label: 'CAT工具',
        component_TaskAssignDialog_dialog_form_item_8_label: '价格区间',

        component_TaskAssignDialog_dialog_form_item_placeholder_1: '请选择',
        component_TaskAssignDialog_dialog_table_column_1: '姓名',
        component_TaskAssignDialog_dialog_table_column_2: '角色',
        component_TaskAssignDialog_dialog_table_column_3: '任务类型',
        component_TaskAssignDialog_dialog_table_column_4: '擅长领域',
        component_TaskAssignDialog_dialog_table_column_5: '单价',
        component_TaskAssignDialog_dialog_table_column_6: '统计单位',
        component_TaskAssignDialog_dialog_table_column_7: '币种',
        component_TaskAssignDialog_dialog_table_column_8: '角色',
        component_TaskAssignDialog_dialog_table_column_9: '任务类型',
        component_TaskAssignDialog_dialog_table_column_10: '任务单价',
        component_TaskAssignDialog_dialog_table_column_11: '计价字数',
        component_TaskAssignDialog_dialog_table_column_12: '任务总价',
        component_TaskAssignDialog_dialog_method_submitAssignEvent_message_1: '请选择负责人',
        component_TaskAssignDialog_dialog_method_rowClickEvent_message_1: '无法分配，请联系用户提供手机号后重试',




        project_task_statistics_page_tab_pane_label_1: '全部',
        project_task_statistics_page_tab_pane_label_2: '待接受',
        project_task_statistics_page_tab_pane_label_3: '进行中',
        project_task_statistics_page_tab_pane_label_4: '已提交',
        project_task_statistics_page_tips_1: '刷新',
        project_task_statistics_page_tips_2: '暂无任务开始时间',
        project_task_statistics_page_tips_3: '暂无任务结束时间',
        project_task_statistics_page_tips_4: '%',
        project_task_statistics_page_form_item_label_1: '所属团队',
        project_task_statistics_page_form_item_label_2: '项目创建人',
        project_task_statistics_page_form_item_label_3: '任务负责人',
        project_task_statistics_page_form_item_label_4: '任务类型',
        project_task_statistics_page_form_item_label_5: '源语言',
        project_task_statistics_page_form_item_label_6: '目标语言',
        project_task_statistics_page_form_item_label_7: '开始时间',
        project_task_statistics_page_form_item_label_8: '结束时间',
        project_task_statistics_page_select_placeholder_1: '所属团队',
        project_task_statistics_page_select_placeholder_2: '项目创建人',
        project_task_statistics_page_select_placeholder_3: '任务负责人',
        project_task_statistics_page_select_placeholder_4: '任务类型',
        project_task_statistics_page_select_placeholder_5: '源语言',
        project_task_statistics_page_select_placeholder_6: '目标语言',
        project_task_statistics_page_select_placeholder_7: '开始时间',
        project_task_statistics_page_select_placeholder_8: '结束时间',
        project_task_statistics_page_input_placeholder_1: '搜索任务',

        project_task_statistics_page_table_column_1: '任务名称',
        project_task_statistics_page_table_column_2: '负责人',
        project_task_statistics_page_table_column_3: '任务类型',
        project_task_statistics_page_table_column_4: '项目名称',
        project_task_statistics_page_table_column_5: '项目创建人',
        project_task_statistics_page_table_column_6: '语言方向',
        project_task_statistics_page_table_column_7: '任务时段',
        project_task_statistics_page_table_column_8: '任务状态',
        project_task_statistics_page_table_column_9: '任务进度',
        project_task_statistics_page_table_column_10: '任务字数',
        project_task_statistics_page_table_column_11: '计价字数',
        project_task_statistics_page_table_column_12: '单价',
        project_task_statistics_page_table_column_13: '币种',
        project_task_statistics_page_table_column_14: '总价',
        project_task_statistics_page_method_downloadBtnEvent_title: '任务统计',

        project_template_page_btn_1: '刷新',
        project_template_page_btn_2: '删除',

        project_template_page_input_placeholder_1: '输入模版名称',

        project_template_page_table_column_1: '项目模板名称',
        project_template_page_table_column_2: '语言方向',
        project_template_page_table_column_3: '项目流程',
        project_template_page_table_column_4: '所属团队',
        project_template_page_table_column_5: '创建人',
        project_template_page_table_column_6: '创建时间',
        project_template_page_table_column_7: '操作',
        project_template_page_dropdown_item_1: '编辑模板名',
        project_template_page_dropdown_item_2: '删除',
        project_template_page_dialog_1_title: '模板重命名',
        project_template_page_dialog_1_input_placeholder_1: '输入模板名称',
        project_template_page_dialog_1_btn_1: '取消',
        project_template_page_dialog_1_btn_2: '确定',
        project_template_page_data_alertInfo_message: '确定要删除该项目模板吗？',
        project_template_page_data_alertInfo_buttonName: '确定',
        project_template_page_method_saveRenameBtnEvent_message_1: '请输入模板名称',
        project_template_page_method_saveRenameBtnEvent_message_2: '重命名成功',
        project_template_page_method_deleteBtnEvent_message_1: '请选择模板',
        project_template_page_method_deleteBtnEvent_message_2: '确认删除选中的项目模板吗？',
        project_template_page_method_deleteTemplateItem_message_1: '确定要删除该项目模板吗？',
        project_template_page_method_deleteConfirmEvent_message_1: '删除成功',





    },
    asset: {
        memory_index_page_button_text_1: '新建记忆库',
        memory_index_page_button_text_2: '合并记忆库',
        memory_index_page_search_input_placeholder: '全量搜索',
        memory_index_page_search_select_placeholder_1: '源语言',
        memory_index_page_search_select_placeholder_2: '目标语言',
        memory_index_page_search_select_placeholder_3: '按团队筛选',
        memory_index_page_card_item_label_1: '记忆库名称：',
        memory_index_page_card_item_label_2: '所属团队：',
        memory_index_page_card_item_label_3: '创建人：',
        memory_index_page_card_item_label_4: '条',
        memory_index_page_card_item_dropdown_label_1: '查看',
        memory_index_page_card_item_dropdown_label_2: '下载Excel',
        memory_index_page_card_item_dropdown_label_3: '下载TMX',
        memory_index_page_card_item_dropdown_label_4: '重命名',
        memory_index_page_card_item_dropdown_label_5: '删除',
        memory_index_page_empty_label_1: '没有找到记忆库～',
        memory_index_page_empty_label_2: '还没有记忆库，去新建一个记忆库吧～',
        memory_index_page_dialog_1_title: '重命名记忆库',
        memory_index_page_dialog_1_form_item_label_1: '名称',
        memory_index_page_dialog_1_form_item_placeholder_1: '输入记忆库名称',
        memory_index_page_dialog_1_btn_label_1: '取消',
        memory_index_page_dialog_1_btn_label_2: '保存',
        memory_index_page_dialog_2_title: '合并记忆库',
        memory_index_page_dialog_2_form_item_label_1: '记忆库名称',
        memory_index_page_dialog_2_form_item_placeholder_1: '输入记忆库名称',
        memory_index_page_dialog_2_form_item_checkbox_label_2: '合并后删除原记忆库',
        memory_index_page_dialog_2_btn_label_1: '取消',
        memory_index_page_dialog_2_btn_label_2: '合并',
        memory_index_page_alert_info_message_1: '确定要删除这个记忆库吗？',
        memory_index_page_alert_info_buttonName_1: '确认删除',
        memory_index_page_validation_message_1: '暂无权限',
        memory_index_page_validation_message_2: '记忆库名称必须填写',
        memory_index_page_validation_message_3: '重命名记忆库成功',
        memory_index_page_validation_message_4: '先选中两个或多个同语言方向的记忆库再合并',
        memory_index_page_validation_message_5: '请选择语言方向相同的记忆库进行合并',
        memory_index_page_validation_message_6: '记忆库名称不能为空',
        memory_index_page_validation_message_7: '记忆库已合并',
        memory_index_page_validation_message_8: '记忆库已删除',

        memory_detail_page_fullLoading_message: '查询中...',
        memory_detail_page_more_info_form_item_label_1: '语言方向',
        memory_detail_page_more_info_form_item_label_2: '条数',
        memory_detail_page_more_info_form_item_label_2_1: '条',
        memory_detail_page_more_info_form_item_label_3: '创建时间',
        memory_detail_page_search_input_placeholder: '输入翻译记忆原文或译文',
        memory_detail_page_search_btn_label_1: '删除',
        memory_detail_page_search_btn_label_2: '上传记忆库',
        memory_detail_page_search_btn_label_3: '下载',
        memory_detail_page_search_btn_label_4: '下载Excel',
        memory_detail_page_search_btn_label_5: '下载TMX',
        memory_detail_page_table_colum_label_1: '序号',
        memory_detail_page_table_colum_label_2: '原文（{value}）',
        memory_detail_page_table_colum_label_3: '译文（{value}）',
        memory_detail_page_table_colum_label_4: '操作',
        memory_detail_page_table_colum_input_placeholder_1: '输入翻译记忆原文',
        memory_detail_page_table_colum_input_placeholder_2: '输入翻译记忆译文',
        memory_detail_page_table_colum_btn_tips_1: '保存',
        memory_detail_page_table_colum_btn_tips_2: '删除',
        memory_detail_page_table_colum_btn_tips_3: '编辑',
        memory_detail_page_table_colum_tips_1: '创建时间：',
        memory_detail_page_table_colum_tips_2: '创建人：',
        memory_detail_page_table_colum_tips_3: '最近更新时间：',
        memory_detail_page_table_colum_tips_4: '最近更新人：',
        memory_detail_page_table_colum_tips_5: '来源文件名：',
        memory_detail_page_table_colum_tips_6: '来源项目名：',
        memory_detail_page_dialog_title_1: '上传记忆库',
        memory_detail_page_dialog_message_1: '支持{value}，点击下载模版：',
        memory_detail_page_dialog_1_btn_1: '取消',
        memory_detail_page_dialog_1_btn_2: '确定',
        memory_detail_page_alertInfo_message: '确定要删除这些翻译记忆吗？',
        memory_detail_page_alertInfo_buttonName: '确认删除',
        memory_detail_page_downloadTemplate_message: '下载中...',
        memory_detail_page_validation_message_1: '获取下载地址失败',
        memory_detail_page_validation_message_2: '翻译记忆原文不能为空',
        memory_detail_page_validation_message_3: '翻译记忆译文不能为空',
        memory_detail_page_validation_message_4: '保存翻译记忆成功',
        memory_detail_page_validation_message_5: '翻译记忆原文不能为空',
        memory_detail_page_validation_message_6: '翻译记忆译文不能为空',
        memory_detail_page_validation_message_7: '保存翻译记忆成功',
        memory_detail_page_validation_message_8: '记忆库名称必须填写',
        memory_detail_page_validation_message_9: '重命名记忆库成功',
        memory_detail_page_validation_message_10: '翻译记忆已删除',
        memory_detail_page_validation_message_11: '请确认已上传文件并上传成功',
        memory_detail_page_validation_message_12: '上传成功',

        term_index_page_search_btn_label_1: '新建术语库',
        term_index_page_search_btn_label_2: '合并术语库',
        term_index_page_search_input_placeholder: '全量搜索',
        term_index_page_search_select_1_placeholder: '源语言',
        term_index_page_search_select_2_placeholder: '目标语言',
        term_index_page_search_select_3_placeholder: '按团队筛选',
        term_index_page_item_tips_1: '术语库名称：',
        term_index_page_item_tips_2: '所属团队：',
        term_index_page_item_tips_3: '创建人：',
        term_index_page_item_tips_4: '条',
        term_index_page_item_dropdown_label_1: '查看',
        term_index_page_item_dropdown_label_2: '下载Excel',
        term_index_page_item_dropdown_label_3: '下载TBX',
        term_index_page_item_dropdown_label_4: '重命名',
        term_index_page_item_dropdown_label_5: '删除',
        term_index_page_empty_message_1: '没有找到术语库～',
        term_index_page_empty_message_2: '还没有术语库，去新建一个术语库吧～',
        term_index_page_dialog_1_title: '重命名术语库',
        term_index_page_dialog_1_form_item_label_1: '名称',
        term_index_page_dialog_1_form_item_placeholder_1: '输入术语库名称',
        term_index_page_dialog_1_btn_label_1: '取消',
        term_index_page_dialog_1_btn_label_2: '保存',
        term_index_page_dialog_2_title: '合并术语库',
        term_index_page_dialog_2_form_item_label_1: '术语库名称',
        term_index_page_dialog_2_form_item_placeholder_1: '输入术语库名称',
        term_index_page_dialog_2_form_item_checkbox_label_1: '合并后删除原术语库',
        term_index_page_dialog_2_btn_label_1: '取消',
        term_index_page_dialog_2_btn_label_2: '保存',
        term_index_page_alertInfo_message: '确定要删除这个术语库吗？',
        term_index_page_alertInfo_buttonName: '确认删除',
        term_index_page_validation_message_1: '暂无权限',
        term_index_page_validation_message_2: '术语库名称必须填写',
        term_index_page_validation_message_3: '重命名术语库成功',
        term_index_page_validation_message_4: '先选中两个或多个同语言方向的术语库再合并',
        term_index_page_validation_message_5: '请选择语言方向相同的术语库进行合并',
        term_index_page_validation_message_6: '术语库名称不能为空',
        term_index_page_validation_message_7: '术语库已合并',
        term_index_page_validation_message_8: '术语库已删除',
        term_detail_page_more_info_form_item_label_1: '语言方向',
        term_detail_page_more_info_form_item_label_2: '条数',
        term_detail_page_more_info_form_item_label_2_1: '条',
        term_detail_page_more_info_form_item_label_3: '创建时间',
        term_detail_page_search_input_placeholder: '输入术语',
        term_detail_page_search_btn_label_1: '删除',
        term_detail_page_search_btn_label_2: '上传术语表',
        term_detail_page_search_btn_label_3: '下载',
        term_detail_page_search_btn_label_4: '下载Excel',
        term_detail_page_search_btn_label_5: '下载TBX',
        term_detail_page_table_colum_label_1: '序号',
        term_detail_page_table_colum_label_2: '原文（{value}）',
        term_detail_page_table_colum_label_3: '译文（{value}）',
        term_detail_page_table_colum_label_4: '备注',
        term_detail_page_table_colum_label_5: '操作',
        term_detail_page_table_colum_input_placeholder_1: '输入术语原文',
        term_detail_page_table_colum_input_placeholder_2: '输入术语译文',
        term_detail_page_table_colum_input_placeholder_3: '输入备注',
        term_detail_page_table_colum_btn_tips_1: '保存',
        term_detail_page_table_colum_btn_tips_2: '删除',
        term_detail_page_table_colum_btn_tips_3: '编辑',
        term_detail_page_table_colum_tips_1: '创建时间：',
        term_detail_page_table_colum_tips_2: '创建人：',
        term_detail_page_table_colum_tips_3: '最近更新时间：',
        term_detail_page_table_colum_tips_4: '最近更新人：',
        term_detail_page_dialog_title_1: '上传术语库',
        term_detail_page_dialog_message_1: '支持{value}，点击下载模版：',
        term_detail_page_dialog_1_btn_1: '取消',
        term_detail_page_dialog_1_btn_2: '确定',
        term_detail_page_alertInfo_message: '确定要删除这些术语吗？',
        term_detail_page_alertInfo_buttonName: '确认删除',
        term_detail_page_downloadTemplate_message: '下载中...',
        term_detail_page_validation_message_1: '术语原文不能为空',
        term_detail_page_validation_message_2: '术语译文不能为空',
        term_detail_page_validation_message_3: '保存术语成功',
        term_detail_page_validation_message_4: '术语原文不能为空',
        term_detail_page_validation_message_5: '术语译文不能为空',
        term_detail_page_validation_message_6: '保存术语成功',
        term_detail_page_validation_message_7: '术语库名称必须填写',
        term_detail_page_validation_message_8: '重命名术语库成功',
        term_detail_page_validation_message_9: '术语已删除',
        term_detail_page_validation_message_10: '获取下载地址失败',
        term_detail_page_validation_message_11: '请确认已上传文件并上传成功',
        term_detail_page_validation_message_12: '上传成功',
        
        component_FulltextSearch_dialog_title_1: '记忆库的全量搜索',
        component_FulltextSearch_dialog_title_2: '术语库的全量搜索',
        component_FulltextSearch_search_input_placeholder: '输入关键词',
        component_FulltextSearch_search_select_placeholder: '请选择',
        component_FulltextSearch_search_select_option_1: '按标题搜索',
        component_FulltextSearch_search_select_option_2: '按内容搜索',
        component_FulltextSearch_search_btn_label: '开始搜索',
        component_FulltextSearch_search_result_total: '查找结果{total}条',
        component_FulltextSearch_search_result_param_name_1: '所属团队：',
        component_FulltextSearch_search_result_param_name_2: '创建人：',
        component_FulltextSearch_search_result_param_name_3: '语向：',
        component_FulltextSearch_search_result_param_name_4: '修改时间：',
        component_FulltextSearch_search_result_param_label_1: '条',
        component_FulltextSearch_search_result_param_label_2: '标题：',
        component_FulltextSearch_search_result_param_label_3: '原文：',
        component_FulltextSearch_search_result_param_label_4: '译文：',
        component_FulltextSearch_search_result_message_1: '加载中...',
        component_FulltextSearch_search_result_message_2: '已经展示全部结果',
        component_FulltextSearch_validation_message_1: '请输入搜索内容',

        component_Tabs_tab_name_1: '记忆库',
        component_Tabs_tab_name_2: '术语库',

        component_CreateMemory_dialog_title: '新建记忆库',
        component_CreateMemory_form_item_label_1: '名称',
        component_CreateMemory_form_item_placeholder_1: '输入记忆库名称',
        component_CreateMemory_form_item_label_2: '语言对',
        component_CreateMemory_form_item_placeholder_2: '源语言',
        component_CreateMemory_form_item_placeholder_3: '目标语言',
        component_CreateMemory_form_item_label_3: '所属团队',
        component_CreateMemory_form_item_placeholder_4: '选择团队',
        component_CreateMemory_form_item_label_4: '上传记忆库',
        component_CreateMemory_form_item_message_1: '支持{type}，最大{size}M。点击下载模版：',
        component_CreateMemory_dialog_btn_label_1: '取消',
        component_CreateMemory_dialog_btn_label_2: '保存',
        component_CreateMemory_downloadTemplate_message: '下载中...',
        component_CreateMemory_validation_message_1: '获取下载地址失败',
        component_CreateMemory_validation_message_2: '记忆库名称必须填写',
        component_CreateMemory_validation_message_3: '语言对的源语言和目标语言都必须选择',
        component_CreateMemory_validation_message_4: '记忆库创建成功',

        component_CreateTerm_dialog_title: '新建术语库',
        component_CreateTerm_form_item_label_1: '名称',
        component_CreateTerm_form_item_placeholder_1: '输入术语库名称',
        component_CreateTerm_form_item_label_2: '语言对',
        component_CreateTerm_form_item_placeholder_2: '源语言',
        component_CreateTerm_form_item_placeholder_3: '目标语言',
        component_CreateTerm_form_item_label_3: '所属团队',
        component_CreateTerm_form_item_placeholder_4: '选择团队',
        component_CreateTerm_form_item_label_4: '上传术语',
        component_CreateTerm_form_item_message_1: '支持{type}，最大{size}M。点击下载模版：',
        component_CreateTerm_dialog_btn_label_1: '取消',
        component_CreateTerm_dialog_btn_label_2: '保存',
        component_CreateTerm_downloadTemplate_message: '下载中...',
        component_CreateTerm_validation_message_1: '获取下载地址失败',
        component_CreateTerm_validation_message_2: '术语库名称必须填写',
        component_CreateTerm_validation_message_3: '语言对的源语言和目标语言都必须选择',
        component_CreateTerm_validation_message_4: '术语库创建成功',


    },
    tools: {
        tools_page_methods_initList_list_1_name: 'AI 润色',
        tools_page_methods_initList_list_1_desc: '基于生成式AI，你的随身母语润色专家',
        tools_page_methods_initList_list_2_name: 'AI 翻译评分',
        tools_page_methods_initList_list_2_desc: '机翻译文智能评分，Al为你推荐参考翻译模型',
        tools_page_methods_initList_list_3_name: '机翻检测',
        tools_page_methods_initList_list_3_desc: '智能标记机翻痕迹，有效识别译文质量风险',
        tools_page_methods_initList_list_4_name: '语料对齐',
        tools_page_methods_initList_list_4_desc: '业内领先的对齐算法，拖拽式对齐体验，AI让语料对齐更简单',
        tools_page_methods_initList_list_5_name: 'AI 译配',
        tools_page_methods_initList_list_5_desc: '一键翻译和配音，瞬间切换语言的AI魔法',
        tools_page_methods_initList_list_5_free: '免费试用开启',
        tools_page_methods_initList_list_6_name: 'QA 检查',
        tools_page_methods_initList_list_6_desc: '不止基于规则的QA，更基于语义理解的智能检查',
        tools_page_methods_initList_list_7_name: '术语提取',
        tools_page_methods_initList_list_7_desc: '智能提取批量文件术语，快速修改即可制作术语库',
        tools_page_methods_goTo_message_1: '请升级到对应的版本后使用。',
    },
    system: {
        slogan: '新一代AI翻译平台',

        basic_left_tagline_1: '全球35款翻译引擎，极致个性化AI翻译',
        basic_left_tagline_2: '基于大模型的AI工具，功能强大/操作极简',
        basic_left_tagline_3: '专业翻译项目管理，完善的多人协作流程',

        login_page_service_terms: '服务协议',
        login_page_privacy_policy: '隐私政策',

        login_page_title: '用户登录',
        login_page_form_title: 'LOGIN',
        login_page_tab_phone: '手机号',
        login_page_tab_account: '邮箱/用户名',
        login_page_form_phone_placeholder: '手机号',
        login_page_form_account_placeholder: '邮箱/用户名',
        login_page_form_password_placeholder: '密码',
        login_page_form_button: '登录',
        login_page_link_forgot: '忘记密码',
        login_page_link_signup: '注册',
        login_page_link_password: '密码登录',
        login_page_link_login: '登录',
        login_page_wechat: '微信登录',
        login_page_wechat_title: '微信登录',
        login_page_wechat_subtitle: '打开微信扫一扫登录',
        login_page_wechat_reload: '点击刷新',
        login_page_wechat_qrcode_overdue: '二维码已过期',
        login_page_bindphone_title: '绑定手机号',
        login_page_bindphone_form_phone_placeholder: '请输入手机号',
        login_page_bindphone_form_captcha_placeholder: '输入验证码',
        login_page_bindphone_form_send_captcha: '发送验证码',
        login_page_bindphone_form_send_captcha_seconds: 's后可发送',
        login_page_form_verification_message_1: '账号和密码不能为空',
        login_page_form_verification_message_2: '请填写手机号',
        login_page_form_verification_message_3: '手机号格式不正确',
        login_page_form_verification_message_4: '手机号和密码不能为空',
        login_page_form_sent_captcha_message: '验证码已发送，请注意查收',
        login_page_bindphone_form_verification_message_1: '请输入手机号',
        login_page_bindphone_form_verification_message_2: '手机号格式不正确',
        login_page_bindphone_form_verification_message_3: '请输入验证码',

        forget_page_title: '忘记密码',
        forget_page_form_title: '忘记密码',
        forget_page_form_phone_placeholder: '输入手机号',
        forget_page_form_captcha_placeholder: '输入验证码',
        forget_page_form_password_placeholder: '输入新密码',
        forget_page_form_button: '确定',
        forget_page_form_verification_message_1: '请输入手机号',
        forget_page_form_verification_message_2: '手机号格式不正确',
        forget_page_form_verification_message_3: '请输入验证码',
        forget_page_form_verification_message_4: '请输入密码',
        forget_page_form_verification_message_5: '密码必须包含小写字母和数字，长度6-20位',
        forget_page_form_submit_success: '密码修改成功, 1s后自动跳转到登录页面。',
        forget_page_form_sent_captcha_message: '验证码已发送，请注意查收',

        signup_page_title: '用户注册',
        signup_page_form_title: '手机号注册',
        signup_page_form_phone_placeholder: '输入手机号',
        signup_page_form_captcha_placeholder: '输入验证码',
        signup_page_form_password_placeholder: '输入密码',
        signup_page_form_invite_code_placeholder: '输入邀请码（选填）',
        signup_page_form_send_captcha: '发送验证码',
        signup_page_form_send_captcha_seconds: 's后可发送',
        signup_page_form_clause_prefix: '注册即代表同意',
        signup_page_form_button: '注册',
        signup_page_form_verification_message_1: '请输入手机号',
        signup_page_form_verification_message_2: '手机号格式不正确',
        signup_page_form_verification_message_3: '请输入验证码',
        signup_page_form_verification_message_4: '请输入密码',
        signup_page_form_verification_message_5: '密码必须包含小写字母和数字，长度6-20位',
        signup_page_form_submit_success: '注册成功,2s后自动进入系统',
        signup_page_form_sent_captcha_message: '验证码已发送，请注意查收',

        check_page_title: '切换组织',
        check_page_switch_header: '切换组织',
        check_page_switch_message_1: '当前正在登录',
        check_page_switch_message_2: '是否要切换至',
        check_page_switch_message_3: '组织？',
        check_page_switch_button_1: '取消',
        check_page_switch_button_2: '确定',
        project_index_page_title: '我的项目',
        project_list_page_title: '项目详情',
        project_template_page_title: '项目模板',
        project_taskStatistics_page_title: '项目任务统计',
        project_create_page_title: '新建项目',
        task_list_index_page_title: '我的任务',
        task_statistics_index_page_title: '任务统计',
        workbench_editer_page_title: '编辑器',
        asset_term_index_page_title: '术语库',
        asset_term_detail_page_title: '术语库详情',
        asset_memory_index_page_title: '记忆库',
        asset_memory_detail_page_title: '记忆库详情',
        tools_page_title: 'AI工具箱',
        tools_align_index_page_title: '语料对齐',
        tools_align_detail_page_title: '语料对齐详情（AI）',
        tools_align_tiled_page_title: '语料对齐详情（规则）',
        tools_termExtraction_index_page_title: '术语提取',
        tools_termExtraction_detail_page_title: '术语提取详情',
        tools_qa_index_page_title: 'QA检查',
        tools_qa_detail_page_title: 'QA检查详情',
        tools_detector_index_page_title: '机翻检测器',
        tools_detector_detail_page_title: '机翻检测结果',
        tools_polish_index_page_title: 'AI润色',
        tools_videoTranslate_index_page_title: 'AI译配',
        tools_videoTranslate_editer_page_title: 'AI译配 - 字幕编辑',
        tools_gptTranslate_index_page_title: '文档智能体',
        tools_gptTranslate_history_page_title: '文档智能体历史',
        tools_translationScore_index_page_title: 'AI翻译评分',
        engine_index_page_title: '我的翻译引擎',
        engine_quickCreate_page_title: '快速训练',
        engine_configNew_page_title: '翻译引擎',
        engine_detail_page_title: '引擎详情',
        engine_share_page_title: '引擎分享',
        team_index_page_title: '团队管理',
        team_invite_page_title: '邀请加入团队',
        account_page_title: '个人中心',
        account_index_page_title: '我的账户',
        account_vip_page_title: '我的会员',
        account_orders_page_title: '我的订单',
        account_invite_page_title: '邀请好友',
        account_chars_page_title: '流量列表',
        account_charHistory_page_title: '流量监测',
        account_orgInfo_page_title: '企业信息',
        account_agentToken_page_title: '智能体Token',
    },
    account: {
    },
    workbench: {
        editer_page_dialog_1_title: '筛选句段',
        editer_page_dialog_1_search_input_label: '关键词',
        editer_page_dialog_1_search_input_placeholder: '输入关键词',
        editer_page_dialog_1_group_1_title: '确认',
        editer_page_dialog_1_group_1_item_1_label: '全部',
        editer_page_dialog_1_group_1_item_2_label: '未确认',
        editer_page_dialog_1_group_1_item_3_label: '已确认',
        editer_page_dialog_1_group_2_title: '锁定',
        editer_page_dialog_1_group_2_item_1_label: '全部',
        editer_page_dialog_1_group_2_item_2_label: '未锁定',
        editer_page_dialog_1_group_2_item_3_label: '已锁定',
        editer_page_dialog_1_group_3_title: '译文来源',
        editer_page_dialog_1_group_3_item_1_label: '全部',
        editer_page_dialog_1_group_3_item_2_label: '翻译记忆',
        editer_page_dialog_1_group_3_item_3_label: '机器翻译',
        editer_page_dialog_1_group_4_title: 'QA',
        editer_page_dialog_1_group_4_item_1_label: '全部',
        editer_page_dialog_1_group_4_item_2_label: '无QA错误',
        editer_page_dialog_1_group_4_item_3_label: '有QA错误',
        editer_page_dialog_1_group_5_title: '批注',
        editer_page_dialog_1_group_5_item_1_label: '全部',
        editer_page_dialog_1_group_5_item_2_label: '无批注',
        editer_page_dialog_1_group_5_item_3_label: '有批注',
        editer_page_dialog_1_btn_1_label: '重置',
        editer_page_dialog_1_btn_2_label: '确认',
        editer_page_dialog_2_title: '提示',
        editer_page_dialog_2_btn_1_label: '取消',
        editer_page_dialog_2_btn_2_label: '返回',
        editer_page_data_loadingText: '加载中…',
        editer_page_methods_initPage_editorPermissionMessage_message: '暂无编辑权限',
        editer_page_methods_saveDocuments_success_message: '保存成功',
        editer_page_methods_applyJargon_error_message: '当前句段为锁定状态，不可应用术语',
        editer_page_methods_applyTranslate_error_message: '当前句段为锁定状态，不可应用机器翻译',
        editer_page_methods_applyMemory_error_message: '当前句段为锁定状态，不可应用翻译记忆',
        editer_page_methods_reTranslate_loadingText: '正在重译所有包含该术语的句段…',
        editer_page_methods_reTranslate_success_message_1: '已重译该条术语所在的 {size} 个句段',
        editer_page_methods_reTranslate_success_message_2: '没有找到需要重译的术语',
        editer_page_methods_repealOrRecoverBtnEvent_success_message_1: '已撤销',
        editer_page_methods_repealOrRecoverBtnEvent_success_message_2: '已恢复',
        editer_page_methods_setSentencesStyle_error_message: '仅支持{types}格式设置样式',
        editer_page_methods_setSplitMode_error_message: '请选择句段',
        editer_page_methods_setSplitMode_info_message: '当前句段已进入拆分模式，确定光标位置即可拆分',
        editer_page_methods_submitSplit_error_message: '不支持光标在句首或者句尾拆分',
        editer_page_methods_submitSplit_success_message: '句段拆分成功',
        editer_page_methods_submitMarge_error_message: '当前已是本页最后一条，无法合并下一句',
        editer_page_methods_submitMarge_error_message_1: '请选择句段',
        editer_page_methods_submitMarge_success_message: '句段已合并',
        editer_page_methods_saveEditSource_success_message: '原文已保存',
        editer_page_methods_qaApplyReferenceEvent_error_message: '暂无编辑权限',
        editer_page_methods_startFillTags_success_message: '已对所有有译文的句段填充{size}句标签',
        editer_page_methods_startFillTags_loadingText_1: 'AI标签填充中，请稍后',
        editer_page_methods_startFillSentenceTags_error_message: '译文不可为空',
        editer_page_methods_startFillSentenceTags_info_message: '正在提取原文标签并插入到译文对应位置中',
        editer_page_methods_setTaskStatus_success_message: '提交成功',
        editer_page_methods_setTaskStatus_loadingText: '提交中，请稍后',
        editer_page_methods_quickSubmit_success_message: '一键确认成功',
        editer_page_methods_quickSubmit_loadingText: '一键确认中，请稍后',
        editer_page_methods_downloadStyleGuide_file_title: '风格指南',
        editer_page_methods_executeAction_message_1: '选择句段后再编辑原文',
        editer_page_methods_executeAction_message_2: '当前句段已进入原文模式，点击原文即可编辑',
        editer_page_methods_executeAction_message_3: 'Pro版专属功能，升级为Pro版可用。',
        editer_page_methods_executeAction_message_3_1: '去升级',
        editer_page_methods_executeAction_message_4: '暂不支持csv、txt、srt等类型的文件填充标签',
        editer_page_methods_beforeRouteLeave_warning_message: '自动保存中，请稍后',

        component_Tools_pageTitle_form_item_label_1: '语言方向',
        component_Tools_pageTitle_engine_name_1: '{name}提供基础翻译',
        component_Tools_pageTitle_engine_name_2: 'LanguageX提供交互式翻译',
        component_Tools_pageTitle_save_tips_1: '保存中...',
        component_Tools_pageTitle_save_tips_2: '已保存',
        component_Tools_pageTitle_progress_tips: '{type}进度：{value}%',
        component_Tools_pageTitle_changeInteractiveMode_select_placeholder: '切换编辑模式',
        component_Tools_pageTitle_changeInteractiveMode_select_option_1: '交互式翻译',
        component_Tools_pageTitle_changeInteractiveMode_select_option_2: '译后编辑',
        component_Tools_pageTitle_submitTask_btn_label: '提交',
        component_Tools_pageTitle_rollbackTask_btn_label: '退回',
        component_Tools_pageTitle_download_btn_label_1: '下载',
        component_Tools_pageTitle_download_btn_label_2: '译文',
        component_Tools_pageTitle_download_btn_label_3: '双语',
        component_Tools_pageTitle_download_btn_label_4: '原文',
        component_Tools_pageTitle_download_btn_label_5: 'TMX',
        component_Tools_actionContent_btn_label_1: '保存',
        component_Tools_actionContent_btn_label_2: '撤销',
        component_Tools_actionContent_btn_label_3: '恢复',
        component_Tools_actionContent_btn_label_4: '查找替换',
        component_Tools_actionContent_btn_label_5: '搜索记忆库',
        component_Tools_actionContent_btn_label_6: '术语定制',
        component_Tools_actionContent_btn_label_7: '全文填充标签',
        component_Tools_actionContent_btn_label_8: '一键确认',
        component_Tools_actionContent_btn_label_9: '拆分',
        component_Tools_actionContent_btn_label_10: '合并',
        component_Tools_actionContent_btn_label_10_title: '合并下一句到当前句',
        component_Tools_actionContent_btn_label_11: '编辑原文',
        component_Tools_actionContent_btn_label_12: '复制原文',
        component_Tools_actionContent_btn_label_12_title: '将当前句段原文内容填充到译文',
        component_Tools_actionContent_btn_label_13: '复制标签',
        component_Tools_actionContent_btn_label_13_title: '将当前句段原文标签填充到译文',
        component_Tools_actionContent_btn_label_14: '获取机翻',
        component_Tools_actionContent_btn_label_15: '锁定',
        component_Tools_actionContent_btn_label_16: '加粗',
        component_Tools_actionContent_btn_label_17: '斜体',
        component_Tools_actionContent_btn_label_18: '下划线',
        component_Tools_actionContent_btn_label_19: '上标',
        component_Tools_actionContent_btn_label_20: '下标',
        component_Tools_actionContent_btn_label_21: '批注',
        component_Tools_actionContent_btn_label_22: '筛选',
        component_Tools_actionContent_btn_label_23: 'QA检查',
        component_Tools_actionContent_btn_label_24: '风格指南',
        component_Tools_actionContent_btn_label_24_title: '下载风格指南',
        component_Tools_actionContent_btn_label_25: '更多设置',
        component_Tools_actionContent_btn_label_26: '字号',
        component_Tools_actionContent_btn_label_27: '快捷键',
        component_Tools_actionContent_btn_label_28: '编辑痕迹',
        component_Tools_actionContent_btn_label_29: '收起',
        component_Tools_actionContent_btn_label_30: '展开',
        component_Tools_actionContent_btn_label_31: '显示空格',
        component_Tools_actionContent_btn_label_32: '隐藏空格',
        component_Tools_dialog_1_title: '任务列表',
        component_Tools_dialog_1_table_column_1: '负责人',
        component_Tools_dialog_1_table_column_2: '任务类型',
        component_Tools_dialog_1_table_column_3: '进度',
        component_Tools_dialog_1_table_column_4: '状态',
        component_Tools_dialog_1_table_column_5: '退回记录',
        component_Tools_dialog_1_table_message_1: '被退回{size}次',
        component_Tools_dialog_2_title: '退回任务',
        component_Tools_dialog_2_message_1: '任务将退回至',
        component_Tools_dialog_2_message_2: '阶段',
        component_Tools_dialog_2_form_item_label_1: '退回原因',
        component_Tools_dialog_2_form_item_placeholder_1: '填写退回原因',
        component_Tools_dialog_2_btn_1: '取消',
        component_Tools_dialog_2_btn_2: '确定',
        component_Tools_dialog_3_title: '字号',
        component_Tools_dialog_3_message_1: '文字大小',
        component_Tools_dialog_3_message_2: '小',
        component_Tools_dialog_3_message_3: '大',
        component_Tools_dialog_4_title: '快捷键',
        component_Tools_dialog_4_table_column_1: '功能',
        component_Tools_dialog_4_table_column_2: '快捷键',
        component_Tools_data_alertInfo_message_1: '切换回交互式翻译模式时，已确认句段会保留，未确认句段会清空，是否继续？',
        component_Tools_data_alertInfo_message_2: '切换回交互式翻译模式时，已确认句段会保留，未确认句段会清空，是否继续？',
        component_Tools_data_alertInfo_message_3: '确定提交吗？',
        component_Tools_data_alertInfo_message_4: '是否把所有句段改为已确认状态？空句段会自动填充机翻译文',
        component_Tools_data_alertInfo_buttonName_1: '确定',
        component_Tools_data_hotkeyList_name_1: '候选片段选择',
        component_Tools_data_hotkeyList_name_2: '整句应用机翻',
        component_Tools_data_hotkeyList_name_3: '译文确认',
        component_Tools_data_hotkeyList_name_4: '快速添加术语',
        component_Tools_data_hotkeyList_name_5: '搜索记忆库',
        component_Tools_method_progressFormat_message: '译者翻译进度：{percentage}%',
        component_Tools_method_jargonSettingEvent_message_1: 'Pro版专属功能，升级为Pro版可用。',
        component_Tools_method_jargonSettingEvent_message_2: '去升级',
        component_Tools_method_addEditFontSize_message: '已经是最大了',
        component_Tools_method_shrinkEditFontSize_message: '已经是最小了',
        component_Tools_method_getMTResult_message_1: '人工翻译，不可获取机翻',
        component_Tools_method_getMTResult_message_2: '请选择句段',
        component_Tools_method_rejectBtnEvent_message: '当前阶段不可退回',
        component_Tools_method_submitRejectEvent_message_1: '请填写退回原因',
        component_Tools_method_submitRejectEvent_message_2: '退回成功',
        component_BasicEditerV3_tips_1: '点击解锁',
        component_BasicEditerV3_tips_2: '点击锁定',
        component_BasicEditerV3_tips_3: '批注',
        component_BasicEditerV3_tips_4: '确定拆分',
        component_BasicEditerV3_tips_5: '退出拆分模式',
        component_BasicEditerV3_tips_6: '保存原文',
        component_BasicEditerV3_tips_7: '退出原文编辑模式',
        component_BasicEditerV3_tips_8: '查看QA检查结果',
        component_BasicEditerV3_tips_9: '确认（Enter）',
        component_BasicEditerV3_tips_10: '确认',
        component_BasicEditerV3_tips_11: '机翻译文疑似有风险',
        component_BasicEditerV3_tips_12: 'LanguageX提供交互式翻译',
        component_BasicEditerV3_tips_13: '{name}翻译',
        component_BasicEditerV3_dialog_1_title: '快速添加术语',
        component_BasicEditerV3_dialog_1_form_item_label_1: '原文',
        component_BasicEditerV3_dialog_1_form_item_placeholder_1: '请选择或输入原文',
        component_BasicEditerV3_dialog_1_form_item_label_2: '译文',
        component_BasicEditerV3_dialog_1_form_item_placeholder_2: '请选择或输入译文',
        component_BasicEditerV3_dialog_1_form_item_label_3: '备注',
        component_BasicEditerV3_dialog_1_form_item_placeholder_3: '请输入备注',
        component_BasicEditerV3_dialog_1_btn_1: '取消',
        component_BasicEditerV3_dialog_1_btn_2: '确定',
        component_BasicEditerV3_data_previewTypeList_name_1: '译文',
        component_BasicEditerV3_data_previewTypeList_name_2: '双语',
        component_BasicEditerV3_data_previewTypeList_name_3: '原文',
        component_BasicEditerV3_data_previewTypeList_name_4: '预览',
        component_BasicEditerV3_method_sourceTagEvent_message_1: '光标必须在译文输入框中',
        component_BasicEditerV3_method_sourceTagEvent_message_2: '当前复制的原文标签和译文输入框不匹配',
        component_BasicEditerV3_method_sourceTagEvent_message_3: '译文不可为空',
        component_BasicEditerV3_method_saveQuicklyAddTermEvent_message_1: '术语原文不能为空',
        component_BasicEditerV3_method_saveQuicklyAddTermEvent_message_2: '术语译文不能为空',
        component_BasicEditerV3_method_saveQuicklyAddTermEvent_message_3: '保存术语成功',
        component_BasicEditerV3_method_getSentSourceText_message_1: '重复句段',
        component_BasicEditerV3_method_getSentSourceText_message_2: '记忆库',
        component_BasicEditerV3_method_getSentSourceText_message_3: '机器翻译',
        component_BasicEditerV3_method_getSentSourceText_message_4: '非译元素',
        component_BasicEditerV3_method_getSentSourceText_message_5: '来源于{names}',
        component_BasicEditerV3_method_previewHtmlBtnEvent_message_1: '获取预览地址失败',
        component_CommentRight_title: '批注',
        component_CommentRight_tips_1: '关闭',
        component_CommentRight_empty_message: '暂无批注',
        component_CommentRight_input_placeholder: '输入批注',
        component_CommentRight_btn_1: '取消',
        component_CommentRight_btn_2: '提交',
        component_CommentRight_tips_2: '回车发送',
        component_CommentRight_data_alertInfo_message: '确定要删除这个批注吗？',
        component_CommentRight_data_alertInfo_buttonName: '确定',
        component_CommentRight_method_sendNewMessageEvent_message_1: '请选择句段后再提交批注',
        component_CommentRight_method_sendNewMessageEvent_message_2: '请输入批注内容',
        component_CommentRight_method_submitDeleteBtnEvent_message_1: '已删除批注',
        component_EditerRight_tips_1: '在我的所有记忆库/术语库中搜索',
        component_EditerRight_tips_2: '查询中...',
        component_EditerRight_tips_3: '点击插入到译文光标位置',
        component_EditerRight_tips_4: '备注：',
        component_EditerRight_tips_5: '记忆库名称：',
        component_EditerRight_tips_6: '创建人：',
        component_EditerRight_tips_7: '创建时间：',
        component_EditerRight_tips_8: '{name}翻译',
        component_EditerRight_tips_9: '翻译中...',
        component_EditerRight_tips_10: '最近更新时间：',
        component_EditerRight_tips_11: '最近更新人：',
        component_EditerRight_tips_12: '术语库名称：',
        component_EditerRight_tips_13: '创建人：',
        component_EditerRight_tips_14: '创建时间：',
        component_EditerRight_tips_15: '最近更新时间：',
        component_EditerRight_tips_16: '最近更新人：',
        component_EditerRight_tips_17: '来源文件名：',
        component_EditerRight_tips_18: '来源项目名：',
        component_EditerRight_tips_19: '来源文件：',
        component_EditerRight_tips_20: '更新人：',

        component_EditerRight_btn_1: '应用',
        component_EditerRight_chunk_title_1: '术语库',
        component_EditerRight_chunk_title_2: '翻译记忆',
        component_EditerRight_chunk_title_3: '机器翻译',
        component_EditerRight_chunk_title_4: '参考引擎',
        component_EditerRight_chunk_empty_message_1: '没有找到相关术语',
        component_EditerRight_chunk_empty_message_2: '段落拆分模式不支持查询翻译记忆',
        component_EditerRight_chunk_empty_message_3: '没有找到相关翻译记忆',
        component_EditerRight_chunk_empty_message_4: '段落拆分模式不支持查询机器翻译',
        component_EditerRight_chunk_empty_message_5: '没有找到其他机器翻译结果',
        
        component_EditRecordRight_title: '编辑痕迹',
        component_EditRecordRight_tips_1: '刷新',
        component_EditRecordRight_tips_2: '关闭',
        component_EditRecordRight_empty_message: '没有留下任何编辑痕迹呢~',

        component_FindMTRight_search_input_placeholder: '在我的所有记忆库/术语库中搜索',
        component_FindMTRight_tabs_item_1: '记忆库',
        component_FindMTRight_tabs_item_2: '术语库',
        component_FindMTRight_tips_1: '复制译文',
        component_FindMTRight_empty_message_1: '暂无内容',
        component_FindMTRight_method_copyEvent_message_1: '已复制到剪切板',
        component_FindMTRight_method_copyEvent_message_2: '复制到剪切板失败，请重试',
        
        component_FindReplaceRight_title: '查找替换',
        component_FindReplaceRight_tips_1: '关闭',
        component_FindReplaceRight_tips_2: '点击定位',
        component_FindReplaceRight_tips_3: '句段',
        component_FindReplaceRight_tips_4: '点击替换',
        component_FindReplaceRight_form_item_1_label: '查找',
        component_FindReplaceRight_form_item_1_input_placeholder: '输入查找的原文或译文',
        component_FindReplaceRight_form_item_2_label: '替换为',
        component_FindReplaceRight_form_item_2_input_placeholder: '输入替换的内容，只能替换译文',
        component_FindReplaceRight_form_checkbox_label: 'Aa区分大小写',
        component_FindReplaceRight_btn_1: '查找',
        component_FindReplaceRight_btn_2: '全部替换',
        component_FindReplaceRight_btn_3: '撤销替换',
        component_FindReplaceRight_empty_message: '暂无内容',

        component_MenuActions_data_actionList_tip_1: '添加术语',
        component_MenuActions_data_actionList_tip_2: '搜索记忆库',
        component_MenuActions_data_actionList_tip_3: '批注',
        component_MenuActions_data_actionList_tip_4: '加粗',
        component_MenuActions_data_actionList_tip_5: '斜体',
        component_MenuActions_data_actionList_tip_6: '下划线',
        component_MenuActions_data_actionList_tip_7: '删除线',
        component_MenuActions_data_actionList_tip_8: '上标',
        component_MenuActions_data_actionList_tip_9: '下标',

        component_PreviewExcel_tips_1: '*预览格式可能和下载后的最终格式不同',
        component_PreviewPPT_tips_1: 'PPT暂不支持预览',
        component_PreviewWord_tips_1: '*预览格式可能和下载后的最终格式不同',
        
        component_QARight_title: 'QA检查',
        component_QARight_tips_1: '关闭',
        component_QARight_tips_2: 'QA设置',
        component_QARight_tips_3: '下载检查结果',
        component_QARight_tips_4: '忽略',
        component_QARight_tips_5:  '操作人：',
        component_QARight_tips_6: '取消忽略',
        component_QARight_tabs_item_1: '全部',
        component_QARight_tabs_item_2: '查看QA忽略历史',
        component_QARight_empty_message_1: '再接再厉~已确认的句段没有检查出质量问题',
        component_QARight_empty_message_2: '暂无忽略的质量问题',
        component_QARight_method_downloadQAResult_title: '{name}QA报告',

        component_ReferenceEngine_dialog_title: '更换参考引擎',
        component_ReferenceEngine_dialog_btn_1: '取消',
        component_ReferenceEngine_dialog_btn_2: '确定',



    },
    config: {
        DOC_SETTING_OPTS_1_name: 'Word设置',
        DOC_SETTING_OPTS_1_options_1_label: '翻译隐藏内容',
        DOC_SETTING_OPTS_1_options_2_label: '翻译页眉、页脚',
        DOC_SETTING_OPTS_1_options_3_label: '翻译批注',
        DOC_SETTING_OPTS_1_options_4_label: '按照Tab分句',
        DOC_SETTING_OPTS_2_name: 'Excel设置',
        DOC_SETTING_OPTS_2_options_1_label: '翻译隐藏内容',
        DOC_SETTING_OPTS_2_options_2_label: '按照段落拆分',
        DOC_SETTING_OPTS_3_name: 'PPT设置',
        DOC_SETTING_OPTS_3_options_1_label: '翻译隐藏内容',

        FILE_ANALYSIS_SETTING_OPTS_1_name: '跨文件重复',
        FILE_ANALYSIS_SETTING_OPTS_2_name: '内部重复',
        FILE_ANALYSIS_SETTING_OPTS_3_name: '非译元素',
        FILE_ANALYSIS_SETTING_OPTS_4_name: '100%',
        FILE_ANALYSIS_SETTING_OPTS_5_name: '101%',
        FILE_ANALYSIS_SETTING_OPTS_6_name: '102%',

        TASK_IMPORT_ERROR_OPTS_TASK_NOT_EXIST: '任务信息不存在',
        TASK_IMPORT_ERROR_OPTS_TASK_STATUS_ABNORMAL: '任务状态异常',
        TASK_IMPORT_ERROR_OPTS_IMPORT_FILE_NON_EMPTY: '导入文件解析数据不能为空',
        TASK_IMPORT_ERROR_OPTS_FILE_TYPE_NOT_SUPPORTS: '不支持的文件类型',
        TASK_IMPORT_ERROR_OPTS_FILE_UPLOAD_FAILURE: '文件上传失败',
        TASK_IMPORT_ERROR_OPTS_FILE_DOES_NOT_EXIST: '解压文件不存在',
        TASK_IMPORT_ERROR_OPTS_NO_AVAILABLE_DATA: '无可执行有效导入数据',
        TASK_IMPORT_ERROR_OPTS_PARSE_TERM_FILE_HAS_ERROR: '解析文件有误',
        TASK_IMPORT_ERROR_OPTS_OPERATION_FAILURE: '操作失败',
    },
    
}


